import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import colors from 'constants/colors';
import NAV_MENU_CONFIG from 'constants/navMenuConfig';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { isLoggedIn } from 'helpers/limitationHelper';
import MenuItem from './MenuItem';

const { MENU, DIVIDER } = ReactTestingLibraryDataProperties;
const { WHITE_75, BLACK } = annaColors;
const { WHITE_15 } = colors;

const CARET_HEIGHT_REM = '0.5';
const MENU_BORDER_RADIUS_REM = '0.625';

type NavMenuProps = {
  isVisible: boolean;
  className?: string;
};

const caretBaseCss = css`
  content: '';
  position: absolute;
  top: 0;
  left: 1.2rem;
  width: 0;
  height: 0;
  border-left: ${CARET_HEIGHT_REM}rem solid transparent;
  border-right: ${CARET_HEIGHT_REM}rem solid transparent;
`;

const Nav = styled.nav.attrs({ 'data-testid': MENU })<NavMenuProps>`
  position: relative;
  padding-top: ${CARET_HEIGHT_REM}rem;
  color: ${WHITE_75};

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          height: auto;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          height: 0;
          pointer-events: none;
        `}

  /* caret background */
  &::before {
    ${caretBaseCss};
    border-bottom: ${CARET_HEIGHT_REM}rem solid ${BLACK};
  }

  /* caret foreground */
  &::after {
    ${caretBaseCss};
    border-bottom: ${CARET_HEIGHT_REM}rem solid ${WHITE_15};
  }
`;

const Background = styled.div`
  border-radius: ${MENU_BORDER_RADIUS_REM}rem;
  background-color: ${BLACK};
`;

const List = styled.ul`
  border-radius: ${MENU_BORDER_RADIUS_REM}rem;
  background-color: ${WHITE_15};
  padding: 1rem 0.5rem;
  list-style: none;
  transition: opacity 0.1s ease;
`;

const StyledLi = styled.li`
  margin: 1px 0;
`;

const Divider = styled.div.attrs({ 'data-testid': DIVIDER })`
  margin: 0.5rem 1rem;
  height: 1px;
  background-color: ${WHITE_15};
`;

const Menu: FC<NavMenuProps> = ({ className, isVisible }) => {
  const { pathname } = useLocation();
  const [isCurrentlyLoggedIn, setIsCurrentlyLoggedIn] = useState(false);

  useEffect(() => {
    setIsCurrentlyLoggedIn(isLoggedIn());
  }, [isVisible]);

  const menuItems = NAV_MENU_CONFIG.map((config, index) => {
    const { shouldShow, type } = config;

    if (
      !shouldShow({
        pathname,
        isCurrentlyLoggedIn,
      })
    ) {
      return null;
    }

    if (type === 'DIVIDER') {
      return <Divider key={`divider-${index}`} />;
    }

    const { text } = config;

    return (
      <StyledLi key={text}>
        <MenuItem {...config} />
      </StyledLi>
    );
  });

  return (
    <Nav className={className} isVisible={isVisible}>
      <Background>
        <List>{menuItems}</List>
      </Background>
    </Nav>
  );
};

export default Menu;
