import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import styled from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { getRemainingFreeStudies, MAX_FREE_STUDIES } from 'helpers/limitationHelper';

const { UPLOADS_REMAINING_CONTAINER } = ReactTestingLibraryDataProperties;
const { WHITE, WHITE_40 } = colors;

const UploadsContainer = styled.div.attrs({ 'data-testid': UPLOADS_REMAINING_CONTAINER })`
  color: ${WHITE_40};
  text-align: center;
  font-size: 0.9rem;
`;

const Counter = styled.span`
  color: ${WHITE};
`;

const UploadsRemaining: FC = () => (
  <UploadsContainer>
    <Counter>{`${getRemainingFreeStudies()}/${MAX_FREE_STUDIES}`}</Counter>
    {' uploads left'}
  </UploadsContainer>
);

export default UploadsRemaining;
