import { push } from 'connected-react-router';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import configuration from 'configuration';
import { BREAK_POINTS } from 'constants/sizes';
import Z_INDEXES from 'constants/zIndexes';
import LogoSizes from 'enums/LogoSizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Routes from 'enums/Routes';
import { isLoggedIn } from 'helpers/limitationHelper';
import { Breadcrumbs, BreadcrumbsSolid } from 'icons/Breadcrumbs';
import LogoDemo from 'icons/LogoDemo';
import LogoViewer from 'icons/LogoViewer';
import Menu from './Menu';

type LogoProps = {
  className?: string;
  size?: LogoSizes;
  shouldRedirect?: boolean;
};

type ContainerProps = {
  size: LogoSizes;
  shouldReduceHeader: boolean;
};

const { LOGO_CONTAINER, LOGO_BREADCRUMBS, LOGO_WRAPPER, NAV_CONTAINER } = ReactTestingLibraryDataProperties;
const { MENU } = Z_INDEXES;
const { XL } = LogoSizes;
const { HEADER_ICON_SHRINK_APP_WIDTH } = BREAK_POINTS;
const { HOME, LIST } = Routes;

const Container = styled.div.attrs({ 'data-testid': LOGO_CONTAINER })<ContainerProps>`
  height: ${({ size }) => `${size}rem`};
  display: flex;
`;

const NavContainer = styled.div.attrs({
  'data-testid': NAV_CONTAINER,
})`
  z-index: ${MENU};
  margin-left: 0.5rem;
  width: 1.75rem;
  padding-top: 0.5rem;
  overflow: visible;
`;

const IconContainer = styled.div.attrs({ 'data-testid': LOGO_BREADCRUMBS })`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledMenu = styled(Menu)`
  margin-left: -0.8rem;
  width: 15rem;
`;

const LogoWrapper = styled.div.attrs({ 'data-testid': LOGO_WRAPPER })`
  width: 5rem;
  cursor: pointer;
`;

const Logo: FC<LogoProps> = ({ className, size = XL, shouldRedirect = true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const BreadcrumbsIcon = isMenuOpen ? BreadcrumbsSolid : Breadcrumbs;
  const { isDemo, isViewer } = configuration;

  const dispatch = useDispatch();
  const shouldReduceHeader = useMediaQuery({
    maxWidth: HEADER_ICON_SHRINK_APP_WIDTH,
  });

  const showMenu = () => {
    setIsMenuOpen(true);
  };

  const hideMenu = () => {
    setIsMenuOpen(false);
  };

  const onLogoClick = () => {
    if (shouldRedirect) {
      if (configuration.isDemo && isLoggedIn()) {
        dispatch(push(LIST));
      } else {
        dispatch(push(HOME));
      }
    }
  };

  return (
    <Container className={className} shouldReduceHeader={shouldReduceHeader} size={size}>
      <LogoWrapper onClick={onLogoClick}>{(isDemo && <LogoDemo />) || (isViewer && <LogoViewer />)}</LogoWrapper>
      {isDemo && (
        <NavContainer onMouseEnter={showMenu} onMouseLeave={hideMenu}>
          <IconContainer>
            <BreadcrumbsIcon size={2} />
          </IconContainer>
          <StyledMenu isVisible={isMenuOpen} />
        </NavContainer>
      )}
    </Container>
  );
};

export default Logo;
