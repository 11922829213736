import React, { FC } from 'react';
import styled from 'styled-components';
import DisplayErrorLastParagraph from 'enums/DisplayErrorLastParagraph';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import DisplayError from 'types/DisplayError';
import Support from './Support';

const TitleContainer = styled.div.attrs({ 'data-testid': ReactTestingLibraryDataProperties.ERROR_TITLE_CONTAINER })`
  font-size: 1.25em;
  padding-bottom: 0.5rem;
`;

const ErrorIdContainer = styled.div.attrs({ 'data-testid': ReactTestingLibraryDataProperties.ERROR_ID_CONTAINER })`
  padding-bottom: 0.75rem;
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.25);
`;

const DescriptionContainer = styled.div`
  font-size: 1em;
  padding: 0 4rem;
`;

const Paragraph = styled.p.attrs({ 'data-testid': ReactTestingLibraryDataProperties.ERROR_PARAGRAPH })`
  margin: 0.25em 0;
  text-align: center;
`;

const UL = styled.ul`
  padding: 1rem 1rem;
`;

const Error: FC<DisplayError> = ({ enumeration, title, errorId, lastParagraph, paragraphs }) => (
  <>
    {title && <TitleContainer>{title}</TitleContainer>}
    {errorId && (
      <ErrorIdContainer>
        ({`Error:`} {errorId})
      </ErrorIdContainer>
    )}
    <DescriptionContainer>
      {paragraphs &&
        paragraphs.map((paragraph, index) => <Paragraph key={`${errorId}${index}`}>{paragraph}</Paragraph>)}
      {enumeration && enumeration.length > 0 && (
        <UL>
          {enumeration.map((bulletPoint, index) => (
            <li key={`${errorId}${index}`}> - {bulletPoint}</li>
          ))}
        </UL>
      )}
      {lastParagraph &&
        (lastParagraph === DisplayErrorLastParagraph.SUPPORT ||
        lastParagraph === DisplayErrorLastParagraph.MORE_UPLOADS_SUPPORT ? (
          <Support message={lastParagraph} errorId={errorId} />
        ) : (
          <Paragraph>{lastParagraph}</Paragraph>
        ))}
    </DescriptionContainer>
  </>
);

export default Error;
