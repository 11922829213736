import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import ChevronDirection from 'enums/ChevronDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { BLACK } = colors;
const { UP, LEFT, DOWN, RIGHT } = ChevronDirection;
const { ICON_CHEVRON_BOLD, ICON_CHEVRON_BOLD_SVG, ICON_CHEVRON_BOLD_PATH } = ReactTestingLibraryDataProperties;

const ROTATE = {
  [UP]: '-90',
  [LEFT]: '180',
  [DOWN]: '90',
  [RIGHT]: '0',
};

const SHADOW = {
  [UP]: '-1px 1px',
  [LEFT]: '-1px -1px',
  [DOWN]: '1px -1px',
  [RIGHT]: '1px 1px',
};

interface ChevronProps extends React.SVGAttributes<SVGElement> {
  direction?: ChevronDirection;
  shadow?: string;
  fill?: string;
}

const Container = styled.div.attrs({
  'data-testid': ICON_CHEVRON_BOLD,
})`
  width: 1rem;
  height: 1rem;
`;

const Svg = styled.svg<{ direction: ChevronDirection; shadow: string }>`
  ${({ direction, shadow }) =>
    css`
      transform: rotate(${ROTATE[direction]}deg);
      filter: drop-shadow(${SHADOW[direction]} 0px ${shadow});
    `}
`;

const ChevronBold: FC<ChevronProps> = ({ direction = RIGHT, fill = '#666', shadow = BLACK }) => (
  <Container>
    <Svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      direction={direction}
      data-testid={ICON_CHEVRON_BOLD_SVG}
      shadow={shadow}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.529 2.861c-.26.26-.26.683 0 .943L9.724 8l-4.195 4.196c-.26.26-.26.682 0 .942.26.26.682.26.942 0l4.667-4.666c.26-.26.26-.683 0-.943L6.471 2.86c-.26-.26-.682-.26-.942 0z"
        fill={fill}
        data-testid={ICON_CHEVRON_BOLD_PATH}
      />
    </Svg>
  </Container>
);

export default ChevronBold;
