import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import LogoSizes from 'enums/LogoSizes';
import HelpLogo from 'icons/Help';
import { EventActions, EventCategories } from 'types/Matomo';
import Modal from './Modal';

const Container = styled.div.attrs({ role: 'button', focusable: true })`
  display: flex;
  border: none;
  line-height: 2em;
  align-items: center;
  color: white;
  border-radius: 0.5em;
  opacity: 0.75;
  padding: 0.65em 1em;

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
  }

  span {
    padding-left: 0.5em;
  }
`;

const HelpText = styled.div`
  line-height: 0;
  margin-left: 0.25rem;
`;

const TitleContainer = styled.div`
  font-size: 1.25em;
  padding-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  margin: 0.5em 0;
  text-align: center;
`;
const { MM } = LogoSizes;
const { REQUESTED_HELP } = EventActions;
const { STUDY_LINK } = EventCategories;

const Help: FC = () => {
  const { trackEvent } = useMatomo();

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    // Only toggle behaviour when modal is closed so email link can be clicked/copied without closing the modal
    !modalOpen && setModalOpen(!modalOpen);

    trackEvent({
      category: STUDY_LINK,
      action: REQUESTED_HELP,
    });
  };

  return (
    <Container onClick={handleClick}>
      <HelpLogo size={MM} />
      <HelpText>Help</HelpText>
      <Modal show={modalOpen} onClose={() => setModalOpen(false)}>
        <TitleContainer>Need help with the Annalise web demo? </TitleContainer>
        <Paragraph>
          Please contact support at{' '}
          <a href={`mailto:demosupport@annalise.ai?subject=Annalise demo support`}>demosupport@annalise.ai</a>
        </Paragraph>
      </Modal>
    </Container>
  );
};

export default Help;
