import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { LUNGS } = ReactTestingLibraryDataProperties;

export const Lungs: FC<Icon> = ({ size = 1.5, color = WHITE }) => (
  <Container size={size} testId={LUNGS}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        d="M10.752 0.100435C10.6577 0.19256 10.5869 1.89689 10.5869 3.87761C10.5869 7.40143 10.5634 7.49356 10.0211 8.00025L9.45522 8.52997L8.93651 7.42446C7.85195 4.98312 6.81454 4.68371 4.7633 6.18076C2.82994 7.60871 1.7218 9.15183 1.03805 11.3168C-0.376598 15.9001 -0.353021 18.5026 1.15594 20.253C1.74538 20.944 1.934 21.0361 2.90067 20.99C4.66898 20.9209 7.3804 19.2396 9.24302 17.0516C9.85603 16.2916 9.87961 16.2225 9.87961 13.2745C9.87961 10.3495 9.90319 10.2343 10.5162 9.45124L11.1528 8.64513L11.7658 9.38214C12.4024 10.0961 12.4024 10.1422 12.2845 12.8599C12.143 16.1995 12.2609 16.5449 14.2414 18.3644C16.7878 20.6676 19.5464 21.6119 20.7253 20.5755C21.7862 19.6312 22.022 18.8711 21.9984 16.4298C21.9749 11.7544 20.3244 8.20753 17.0943 5.90438C15.4675 4.7528 14.0528 5.2595 13.2748 7.21718C12.8268 8.36875 12.7089 8.43785 12.1666 7.83903C11.8365 7.49356 11.7658 6.75655 11.7187 3.76245C11.6479 0.653191 11.6008 0.077404 11.2943 0.00830841C11.0821 -0.0147228 10.8463 0.00830841 10.752 0.100435ZM7.33324 7.12505C8.06414 8.06934 8.48854 10.0501 8.63 13.0211L8.77147 15.831L7.33324 17.2129C5.82428 18.6638 3.65515 19.8615 2.75921 19.7233C1.22667 19.516 0.943741 15.5316 2.21693 11.7083C2.80636 9.91187 2.99498 9.63549 4.59825 8.06934C5.54135 7.12505 6.46087 6.41107 6.62592 6.45714C6.76738 6.5032 7.09747 6.80261 7.33324 7.12505ZM17.5423 8.06934C19.1927 9.68155 19.287 9.84277 20.0179 12.0538C21.3618 16.0843 21.0318 19.7694 19.3578 19.7694C18.3439 19.7694 16.4813 18.756 15.0195 17.3971C13.9114 16.3377 13.6756 15.9922 13.5341 15.094C13.3219 13.8042 13.7935 9.52033 14.265 8.20753C14.6187 7.21718 15.2081 6.41107 15.5854 6.41107C15.7033 6.41107 16.5756 7.17111 17.5423 8.06934Z"
        fill={color}
      />
    </svg>
  </Container>
);

export const LungsSolid: FC<Icon> = ({ size = 1.5, color = WHITE }) => (
  <Container size={size} testId={LUNGS}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        d="M10.752 0.100435C10.6577 0.192562 10.5869 1.89689 10.5869 3.87761C10.5869 7.40143 10.5634 7.49356 10.0211 8.00025L9.45522 8.52997L8.93651 7.42446C7.85195 4.98312 6.81454 4.68371 4.7633 6.18076C2.82994 7.60871 1.7218 9.15183 1.03805 11.3168C-0.376598 15.9001 -0.353021 18.5026 1.15594 20.253C1.74538 20.944 1.934 21.0361 2.90067 20.99C4.66898 20.9209 7.3804 19.2396 9.24302 17.0516C9.85603 16.2916 9.87961 16.2225 9.87961 13.2745C9.87961 10.3495 9.90319 10.2343 10.5162 9.45124L11.1528 8.64513L11.7658 9.38214C12.4024 10.0961 12.4024 10.1422 12.2845 12.8599C12.143 16.1995 12.2609 16.5449 14.2414 18.3644C16.7878 20.6676 19.5464 21.6119 20.7253 20.5755C21.7862 19.6312 22.022 18.8711 21.9984 16.4298C21.9749 11.7544 20.3244 8.20753 17.0943 5.90438C15.4675 4.7528 14.0528 5.2595 13.2748 7.21718C12.8268 8.36876 12.7089 8.43785 12.1666 7.83903C11.8365 7.49356 11.7658 6.75655 11.7187 3.76245C11.6479 0.653191 11.6008 0.0774021 11.2943 0.00830841C11.0821 -0.0147228 10.8463 0.00830841 10.752 0.100435Z"
        fill="#808080"
      />
    </svg>
  </Container>
);
