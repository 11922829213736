import styled from 'styled-components';

const PaginationButton = styled.button`
  margin: 0;
  outline: none;
  border-radius: 0.5em;
  padding: 4px 8px;
  border: 0;
  color: white;
  background-color: inherit;

  &:hover {
    color: #5436bd;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #848484;
    cursor: not-allowed;
  }
`;

export default PaginationButton;
