import { SegmentLateralityType } from '@annaliseai/api-specifications';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import StudyResultThumbnailTypes from 'enums/StudyResultThumbnailTypes';
import ViewPositionMapping from 'enums/viewPositionMapping';
import getStudyResultThumbnailType from 'helpers/getStudyResultThumbnailType';
import NoEye from 'icons/NoEye';
import selectCxrViewer from 'selectors/viewer/cxr/selectCxrViewer';
import selectImagesMap from 'selectors/viewer/selectImagesMap';
import Localisation from 'types/study/Localisation';
import { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';
import Thumbnail from './Thumbnail';
import ThumbnailBadge from './ThumbnailBadge';

type StudyResultThumbnailsProps = {
  views: CxrFindingView[];
  activeViewData?: CxrFindingView;
  localisation?: Localisation | null;
};

const { NONE } = SegmentLateralityType;
const { THUMBNAILS_CONTAINER, STUDY_RESULT_THUMBNAILS } = ReactTestingLibraryDataProperties;
const { WITH_BADGE } = StudyResultThumbnailTypes;
const { UNKNOWN } = ViewPositionMapping;

const NoLocalisationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 0.4rem;
  // Opacity of 40% for both the text and image to appear grey
  opacity: 0.4;
`;

const TextContainer = styled.div`
  padding-right: 0.5rem;
  font-size: 0.875rem;
`;

const IconContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;

  svg {
    display: inline;
    vertical-align: text-top;
  }
`;

const ThumbnailsContainer = styled.div.attrs({ 'data-testid': THUMBNAILS_CONTAINER })`
  display: flex;
  justify-content: right;
  margin-left: 1.5rem;
  span {
    height: 1rem;
    font-size: 0.75rem;
    padding: 0.625rem 0.25rem;
    align-self: center;
    margin-right: 0.5rem;
  }
`;

const CxrStudyResultThumbnails: FC<StudyResultThumbnailsProps> = ({ views, localisation, activeViewData }) => {
  const { imagesMetadataMap } = useSelector(selectCxrViewer);
  const imagesMap = useSelector(selectImagesMap);

  return (
    <div data-testid={STUDY_RESULT_THUMBNAILS}>
      {(localisation?.type === 'LATERALITY' && localisation?.laterality === NONE) || !localisation ? (
        <NoLocalisationContainer>
          <TextContainer>No localisation</TextContainer>
          <IconContainer>
            <NoEye />
          </IconContainer>
        </NoLocalisationContainer>
      ) : (
        views.length > 1 && (
          <ThumbnailsContainer>
            {getStudyResultThumbnailType() === WITH_BADGE && activeViewData && (
              <ThumbnailBadge>
                {
                  ViewPositionMapping[
                    (imagesMetadataMap[activeViewData.baseImageUuid]?.viewPosition ||
                      UNKNOWN) as keyof typeof ViewPositionMapping
                  ]
                }
              </ThumbnailBadge>
            )}
            {views.map(view => {
              return (
                <Thumbnail
                  key={view.uuid}
                  viewUuid={view.uuid}
                  url={imagesMap[view.thumbnailImageUuid].url}
                  isSelected={view.uuid === activeViewData?.uuid || false}
                />
              );
            })}
          </ThumbnailsContainer>
        )
      )}
    </div>
  );
};

export default CxrStudyResultThumbnails;
