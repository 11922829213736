// The getDateTimeTitle determines the title (tooltip) for use on study/created dateTime fields
// If dateTime is valid, the title will replicate it.
// If dateTime is not valid, the title will be created from the dateTitle and timeTitle fields.
// If it is not possible to provide the dateTime, a message will be returned stating this.

const getDateTimeTitle = (
  dateTime?: string,
  studyDate?: string,
  studyTime?: string,
  dateTitle?: string,
  timeTitle?: string,
): string => {
  if (!dateTime && !studyDate && !studyTime) {
    return 'Date and time not available';
  } else {
    return dateTime || `${dateTitle} ${timeTitle}`;
  }
};

export default getDateTimeTitle;
