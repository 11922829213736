import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Disclaimer } from 'components';
import ButtonIconContainer from 'components/ButtonIconContainer';
import Header from 'components/Header';
import PrimaryButton from 'components/PrimaryButton';
import StudiesDataTable from 'components/StudiesDataTable';
import Routes from 'enums/Routes';
import { FooterContainer } from 'layouts/ResponsiveContainers';
import { studyListActions } from 'slices/studyListSlice';

const { STAGE } = Routes;

const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0px 0px;
  grid-template-areas:
    'nav'
    'main';
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  padding: 0 8rem;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: main;
`;

const UploadButtonContainer = styled.div`
  height: 3rem;
`;

const ListScreen: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(studyListActions.fetch());
  }, [dispatch]);

  const onUploadClick = () => {
    dispatch(push(STAGE));
  };

  return (
    <Container>
      <Header>
        <UploadButtonContainer>
          <PrimaryButton onClick={onUploadClick}>
            <ButtonIconContainer>
              <FontAwesomeIcon icon={faCloudUploadAlt} />
            </ButtonIconContainer>
            <span>Upload CXR study</span>
          </PrimaryButton>
        </UploadButtonContainer>
      </Header>
      <BodyContainer>
        <StudiesDataTable />
      </BodyContainer>
      <FooterContainer>
        <Disclaimer />
      </FooterContainer>
    </Container>
  );
};

export default ListScreen;
