import React, { FC } from 'react';
import styled from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { SUPPORT } = ReactTestingLibraryDataProperties;

type SupportProps = {
  message: string;
  errorId: string;
};

const Paragraph = styled.p.attrs({ 'data-testid': SUPPORT })`
  text-align: center;
  margin: 0.25em 0;
`;

const Support: FC<SupportProps> = ({ message, errorId }) => (
  <Paragraph>
    {message} please contact support at{' '}
    <a href={`mailto:demosupport@annalise.ai?subject=Annalise demo support for ${errorId}`}>demosupport@annalise.ai</a>.
  </Paragraph>
);

export default Support;
