import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import { push } from 'connected-react-router';
import React, { FC, MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Chevron from 'icons/Chevron';
import ModalityThumbnailCardType from 'types/ModalityThumbnailCardType';

const { BACKGROUNDS_WINDOW } = colors;
const { PRIMARY } = annaColors;
const { MODALITY_THUMBNAIL_CARD_IMAGE, MODALITY_THUMBNAIL_CARD_CONTAINER } = ReactTestingLibraryDataProperties;

const OVERLAP_BREAKPOINT = 834;

const ModalityThumbnailCardContainer = styled.div.attrs({
  'data-testid': MODALITY_THUMBNAIL_CARD_CONTAINER,
})`
  @media (max-height: ${OVERLAP_BREAKPOINT}px) {
    width: 18rem;
    height: 20rem;
    padding: 1rem 1rem 0 1rem;
  }
  width: 26rem;
  height: 29.5rem;
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${BACKGROUNDS_WINDOW};
  transition: 0.2s ease-in-out;
  :hover {
    cursor: pointer;
    background-color: ${PRIMARY};
  }
`;

const ImageContainer = styled.div.attrs({
  'data-testid': MODALITY_THUMBNAIL_CARD_IMAGE,
})`
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  overflow: hidden;
  > img {
    width: 100%;
  }
`;

const Label = styled.h3`
  height: 4.5rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    height: 2.812rem;
    width: 2.812rem;
    margin-right: -1rem;
  }
`;

const ModalityThumbnailCard: FC<ModalityThumbnailCardType> = ({ label, image, url }) => {
  const dispatch = useDispatch();

  const goToModalityPage = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      dispatch(push(url));
    },
    [dispatch, url],
  );

  return (
    <ModalityThumbnailCardContainer onClick={goToModalityPage}>
      <ImageContainer>{typeof image === 'string' ? <img src={image} alt={label} /> : image}</ImageContainer>
      <Label>
        {label} <Chevron active={true} />
      </Label>
    </ModalityThumbnailCardContainer>
  );
};

export default ModalityThumbnailCard;
