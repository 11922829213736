import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAK_POINTS } from 'constants/sizes';
import Modal from './Modal';

const { SMALLEST_WIDTH, SMALLEST_HEIGHT } = BREAK_POINTS;

const SmallScreenBlockingModal: FC = _ => {
  const isUnsupportedHeight = useMediaQuery({
    maxHeight: SMALLEST_HEIGHT - 1,
  });

  const isUnsupportedWidth = useMediaQuery({
    maxWidth: SMALLEST_WIDTH - 1,
  });

  const unsupportedRatios = isUnsupportedHeight || isUnsupportedWidth;
  return (
    <Modal show={unsupportedRatios} hasFooter={false}>
      <p role="dialog">
        Sorry Annalise demo is not currently compatible with your screen resolution. The minimum supported resolution is{' '}
        {SMALLEST_WIDTH}x{SMALLEST_HEIGHT}.
      </p>
      <br />
      <p>Please try again with a larger browser size or adjusting your browser zoom settings.</p>
    </Modal>
  );
};

export default SmallScreenBlockingModal;
