import { CxrPredictionCompleted, Status } from '@annaliseai/api-specifications';
import { Epic, ofType } from 'redux-observable';
import { interval, of, throwError } from 'rxjs';
import { exhaustMap, first, map, mergeMap, switchMap, timeoutWith } from 'rxjs/operators';
import { postImagesStatus, postSegmentsStatus } from 'api/queries';
import getCxrPrediction from 'api/queries/getCxrPrediction';
import getErrorCode from 'api/queries/getErrorCode';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import getCxrStudyNormalisedData from 'epics/study/helpers/cxr/getCxrStudyNormalisedData';
import { getImageIds } from 'helpers/cxrImageHelper';
import CustomError from 'helpers/error/CustomError';
import { ActionTypes } from 'slices/ActionTypes';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import { findingsMenuActions } from 'slices/findingsMenuSlice';
import { findingsActions } from 'slices/findingsSlice';
import { imagesActions } from 'slices/imagesSlice';
import { localisationsActions } from 'slices/localisationsSlice';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { COMPLETED, COMPLETED_ERROR } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const initCxrStudyEpic: Epic<ActionTypes> = action$ =>
  action$.pipe(
    ofType<ActionTypes, ReturnType<typeof cxrViewerActions.runInitCxrStudyEffect>>(
      cxrViewerActions.runInitCxrStudyEffect.type,
    ),
    switchMap(({ payload: studyExternal }) =>
      interval(PERIOD).pipe(
        exhaustMap(async () => await getCxrPrediction(studyExternal.study.studyInstanceUid)),
        map(prediction => {
          const { status } = prediction;
          if (status === COMPLETED_ERROR) {
            throw new CustomError(getErrorCode(prediction.error.code));
          }

          return {
            prediction,
            studyExternal,
          };
        }),
        first(
          (data): data is { prediction: CxrPredictionCompleted; studyExternal: typeof studyExternal } =>
            data.prediction.status === COMPLETED,
        ),
        timeoutWith(TIMEOUT, throwError(new CustomError(UNEXPECTED_BACKEND_ERROR))),
      ),
    ),
    mergeMap(async data => {
      const { accessionNumber } = data.studyExternal.study;
      const imageIds = getImageIds(data.prediction.result.images);

      const [postImageStatusRes, postSegmentsStatusRes] = await Promise.all([
        postImagesStatus(accessionNumber, imageIds),
        postSegmentsStatus(accessionNumber, data.prediction.id),
      ]);
      return { ...data, postImageStatusRes, postSegmentsStatusRes };
    }),
    mergeMap(data => {
      const {
        imagesMap,
        localisationsMap,
        findingsMap,
        findingViewsMap,
        findingViewersMap,
        findingItemsMap,
        findingsGroupsMap,
        findingsMenu,
        defaultBaseImageUuid,
        imagesMetadataMap,
        studyDescription,
      } = getCxrStudyNormalisedData(data);

      return of(
        imagesActions.setImagesMap(imagesMap),
        localisationsActions.setLocalisationsMap(localisationsMap),
        findingsActions.setFindingsMap(findingsMap),
        cxrViewerActions.setFindingViewersMap({
          findingViewsMap,
          findingViewersMap,
          defaultBaseImageUuid,
          imagesMetadataMap,
        }),
        cxrViewerActions.setStudyDescription(studyDescription),
        findingsMenuActions.setFindingsMenu({ findingsMenu, findingsGroupsMap, findingItemsMap }),
      );
    }),
  );

export default initCxrStudyEpic;
