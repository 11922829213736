import Cookies from 'js-cookie';
import { v4 } from 'uuid';
import CookieKeys from 'enums/CookieKeys';
import { retrieveAiGdpr5 } from './aiGdpr5Helper';

const retrieveAnalyticsUserId = (): string => {
  let userId = Cookies.getJSON(CookieKeys.ANALYTICS_USER_ID);
  const cookieAccepted = !!retrieveAiGdpr5();

  if (!userId && cookieAccepted) {
    userId = v4();
    Cookies.set(CookieKeys.ANALYTICS_USER_ID, userId);
  }

  return userId;
};

export default retrieveAnalyticsUserId;
