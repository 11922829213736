import React, { FC } from 'react';
import styled, { css } from 'styled-components';

type IconContainerProps = {
  size?: number;
  testId: string;
};

const IconContainer = styled.div.attrs<IconContainerProps>(({ testId }) => ({
  'data-testid': testId,
}))<IconContainerProps>`
  ${({ size }) =>
    css`
      width: ${size}rem;
      height: ${size}rem;
    `}
`;

const Container: FC<IconContainerProps> = ({ children, size, testId }) => (
  <IconContainer {...{ size, testId }}>{children && children}</IconContainer>
);

export default Container;
