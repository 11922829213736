import { StudyExternalV2, PostFilterStudiesV2Res } from '@annaliseai/api-specifications';
import { interval, throwError } from 'rxjs';
import { exhaustMap, first, timeoutWith } from 'rxjs/operators';
import { postStudiesFilter } from 'api/queries';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { STUDY_NOT_FOUND } = CustomErrors;
const { PERIOD, TIMEOUT } = POLLING;

const loadStudyExternal = async (accessionNumber: string, studyInstanceUid: string): Promise<StudyExternalV2> => {
  const postStudiesFilterResponse: PostFilterStudiesV2Res = await interval(PERIOD)
    .pipe(
      exhaustMap(async () => await postStudiesFilter(accessionNumber)),
      first((data): data is PostFilterStudiesV2Res => data.studies !== null),
      timeoutWith(TIMEOUT, throwError(new CustomError(STUDY_NOT_FOUND))),
    )
    .toPromise();

  const studyExternal = postStudiesFilterResponse.studies.filter(
    studyExternal => studyExternal.study.studyInstanceUid === studyInstanceUid,
  )[0];

  if (!studyExternal) {
    throw new CustomError(STUDY_NOT_FOUND);
  }

  return studyExternal;
};

export default loadStudyExternal;
