import { arrowLeft24Filled, arrowRight24Filled } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import { Grid, TextContainer, WorklistPaginationButton } from './WorklistPagination.styles';

type WorklistPaginationProps = {
  handleNext: () => void;
  handlePrevious: () => void;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
};

const WorklistPagination: FC<WorklistPaginationProps> = ({
  handleNext,
  handlePrevious,
  isPrevDisabled,
  isNextDisabled,
}) => {
  return (
    <Grid>
      <WorklistPaginationButton onClick={() => handlePrevious()} disabled={isPrevDisabled} title="Previous">
        <IconWrapper svg={arrowLeft24Filled} size={1} />
        <TextContainer>Previous</TextContainer>
      </WorklistPaginationButton>
      <WorklistPaginationButton onClick={() => handleNext()} disabled={isNextDisabled} title="Next">
        <TextContainer>Next</TextContainer>
        <IconWrapper svg={arrowRight24Filled} size={1} />
      </WorklistPaginationButton>
    </Grid>
  );
};

export default WorklistPagination;
