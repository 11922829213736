import { colors } from '@annaliseai/anna-design-tokens';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import TabItemWithIcon from './TabItemWithIcon';
import { TabsProps, TabId } from './types';

const { PRIMARY } = colors;

const Container = styled.div`
  width: 100%;
  max-height: 2.125rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(217, 217, 217, 0.2);
`;

const TabItem = styled.div<{ isActive: boolean }>`
  min-width: 13.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 1.25rem;
  padding: 0 1rem 0.675rem 1rem;
  cursor: pointer;
  ::after {
    transition: all 0.4s ease;
    content: ' ';
    height: 2px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  ${({ isActive }) => css`
    ::after {
      background-color: ${isActive ? PRIMARY : 'transparent'};
    }
  `}
`;

const Tabs: FC<TabsProps> = ({ tabs, initialActiveTab, onChange }) => {
  const [activeTabId, setActiveTabId] = useState<TabId>(initialActiveTab || tabs[0].id);
  return (
    <Container>
      {tabs.map(({ id, label, regularIcon, activeIcon, size, fill }) => (
        <TabItem
          key={id}
          isActive={id === activeTabId}
          onClick={() => {
            if (id !== activeTabId) {
              setActiveTabId(id);
              onChange(id);
            }
          }}
        >
          <TabItemWithIcon
            label={label}
            icon={<IconWrapper fill={fill} svg={id === activeTabId ? activeIcon : regularIcon} size={size} />}
          />
        </TabItem>
      ))}
    </Container>
  );
};

export default Tabs;
