import { bool, cleanEnv, num, str, url } from 'envalid';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

type ApplicationMode = 'demo' | 'viewer';

type TrackingConfig = {
  enabled: boolean;
  siteId?: number;
  accountUrl?: string;
};

type Configuration = {
  appMode: ApplicationMode;
  apiUrl: string;
  tracking: TrackingConfig;
  readonly isDemo: boolean;
  readonly isViewer: boolean;
};

const env = cleanEnv(process.env, {
  REACT_APP_APPLICATION_MODE: str({ choices: ['demo', 'viewer'], default: 'demo' }),
  REACT_APP_API: url(),
  REACT_APP_TRACKING_ENABLED: bool({ default: false }),
  REACT_APP_TRACKING_SITE_ID: num({ default: undefined }),
  REACT_APP_TRACKING_ACCOUNT_URL: str({ default: undefined }),
});

if (env.REACT_APP_TRACKING_ENABLED) {
  if (env.REACT_APP_TRACKING_SITE_ID === undefined || env.REACT_APP_TRACKING_ACCOUNT_URL === undefined) {
    const { INVALID_ENVIRONMENT_VARIABLE } = CustomErrors;
    throw new CustomError(
      INVALID_ENVIRONMENT_VARIABLE,
      'Invalid REACT_APP_TRACKING_SITE_ID or REACT_APP_TRACKING_ACCOUNT_URL value',
    );
  }
}

/**
 * App configuration
 *
 * Should:
 * - Contain any configuration the app needs
 * - Be the centralised point to access environment variables
 *
 * Can:
 * - Have helpers to access configuration values as booleans to make it easier to write conditionals ie if(configuration.isDemo) { ... }
 *
 * Usage:
 *  import configuration from 'configuration';
 *  { ... }
 *  configuration.appMode
 *
 */
const configuration: Configuration = {
  appMode: env.REACT_APP_APPLICATION_MODE,
  apiUrl: env.REACT_APP_API,
  get tracking() {
    if (env.REACT_APP_TRACKING_ENABLED) {
      return {
        enabled: env.REACT_APP_TRACKING_ENABLED,
        siteId: env.REACT_APP_TRACKING_SITE_ID,
        accountUrl: env.REACT_APP_TRACKING_ACCOUNT_URL,
      };
    }
    return {
      enabled: env.REACT_APP_TRACKING_ENABLED,
    };
  },
  // some helpers in form of getters to make dev life easier
  // these can be called like configuration.isDemo
  get isDemo() {
    return configuration.appMode === 'demo';
  },
  get isViewer() {
    return configuration.appMode === 'viewer';
  },
};

export default configuration;
export type { Configuration };
