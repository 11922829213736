import React, { FC } from 'react';
import styled from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_PRIORITY } = ReactTestingLibraryDataProperties;

const Container = styled.div.attrs({
  'data-testid': ICON_PRIORITY,
})`
  height: 1rem;
  width: 1rem;
  margin: 0.25rem;
`;

const Priority: FC = () => (
  <Container>
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <filter id="shadow">
        <feDropShadow dx="0.8" dy="0.8" stdDeviation="0" />
      </filter>
      <g filter="url(#shadow)">
        <path
          d="M6.363 1.624c.607-.336 1.364-.141 1.736.431l.048.08 4.526 8.172c.108.195.165.414.165.636 0 .695-.54 1.264-1.223 1.31l-.09.003h-9.05c-.223 0-.442-.057-.636-.165-.607-.335-.843-1.08-.555-1.7l.042-.084 4.525-8.171c.12-.216.297-.393.512-.512zM7 9.335c-.322 0-.582.26-.582.583 0 .321.26.582.582.582.322 0 .583-.26.583-.582 0-.322-.261-.583-.583-.583zM7 4.666c-.3 0-.546.226-.58.516l-.003.068v2.917l.005.068c.034.29.28.515.58.515.299 0 .545-.225.579-.515l.004-.069-.002-2.917-.003-.068c-.034-.29-.28-.515-.58-.515z"
          fill="#666"
        />
      </g>
    </svg>
  </Container>
);

export default Priority;
