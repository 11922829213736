import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import { push } from 'connected-react-router';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { MENU_ITEM } = ReactTestingLibraryDataProperties;
const { GREY_2, GREY_6 } = colors;
const { WHITE, WHITE_75 } = annaColors;

type IconProps = {
  color?: string;
  size?: number;
  fillOpacity?: number;
};

export type Payload = {
  redirect?: string;
};

type MenuItemProps = {
  action?: (payload: Payload) => void;
  href?: string;
  Icon?: FC<IconProps>;
  IconHover?: FC<IconProps>;
  payload?: Payload;
  route?: string;
  text: string;
};

const NavLink = styled.a.attrs({ 'data-testid': MENU_ITEM })`
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  height: 2rem;
  transition: background-color 0.1s ease;
  cursor: pointer;

  :hover {
    background-color: ${GREY_2};
    color: ${WHITE};
  }

  :active {
    background-color: ${WHITE_75};
    color: ${GREY_2};
  }
`;

const IconContainer = styled.div`
  padding: 0 0.4rem 0 0;
`;

const MenuItem: FC<MenuItemProps> = ({ action, href, Icon, IconHover, route, payload, text }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const dispatch = useDispatch();

  const onMenuItemEnter = () => {
    setIsHovered(true);
  };
  const onMenuItemMouseLeaveOrUp = () => {
    setIsHovered(false);
    setIsPressed(false);
  };
  const onMenuItemMouseDown = () => {
    setIsPressed(true);
    if (action && payload) {
      dispatch(action(payload));
    }
    if (route) {
      dispatch(push(route));
    }
  };

  return (
    <NavLink
      href={href}
      target={href && '_blank'}
      onMouseDown={onMenuItemMouseDown}
      onMouseEnter={onMenuItemEnter}
      onMouseLeave={onMenuItemMouseLeaveOrUp}
      onMouseUp={onMenuItemMouseLeaveOrUp}
    >
      {Icon && IconHover && (
        <IconContainer>
          {isHovered ? (
            <IconHover fillOpacity={0.75} color={isPressed ? GREY_2 : GREY_6} />
          ) : (
            <Icon fillOpacity={0.75} color={GREY_6} />
          )}
        </IconContainer>
      )}
      {text && text}
    </NavLink>
  );
};

export default MenuItem;
