import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useInterval from 'hooks/useInterval';
import { selectStudies, selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';
import DisplayStudyInformation from 'types/DisplayStudyInformation';

export const POLL_STUDIED_INTERVAL = 60_000;

const usePolledStudies = (): DisplayStudyInformation[] => {
  const studies = useSelector(selectStudies, shallowEqual);
  const studiesQuery = useSelector(selectStudiesQuery);
  const dispatch = useDispatch();

  // initial fetch, and on query param changes
  useEffect(() => {
    dispatch(studyListActions.fetch(studiesQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesQuery]);

  // periodic fetch
  useInterval(() => {
    dispatch(studyListActions.fetch(studiesQuery));
  }, POLL_STUDIED_INTERVAL);

  return studies || [];
};

export default usePolledStudies;
