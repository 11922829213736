import {
  CtbClassificationExternal,
  CtbGroupedRelevantClassificationExternal,
  VisionClassificationExternal,
} from '@annaliseai/api-specifications';
import { VisionClassificationsGroupExternal } from '@annaliseai/api-specifications/build/definitions/core/VisionFindingsExternal';
import IRRELEVANT_FINDINGS_TEXT from 'constants/findingsMenu';
import createMapFromArrayValues from 'helpers/createMapFromArrayValues';
import isFirstFindingsGroup from 'helpers/cxrFinding/isFirstFindingsGroup';
import uuid from 'helpers/uuid';
import Finding, { Parent } from 'types/study/Finding';
import Uuid from 'types/Uuid';
import FindingsMenu, { FindingItem, FindingsGroup, FindingType, ParentFindingItem } from 'types/viewer/FindingsMenu';
import getFindingItems from './getFindingItems';

const getFindingsMenu: ({
  relevantClassifications,
  irrelevantClassifications,
  findingsMapByLabel,
  parentsMapByLabel,
}: {
  relevantClassifications: (
    | (CtbGroupedRelevantClassificationExternal & {
        predictions: FindingType[];
      })
    | VisionClassificationsGroupExternal
  )[];
  irrelevantClassifications: (CtbClassificationExternal | VisionClassificationExternal)[];
  findingsMapByLabel: Record<Finding['uuid'], Finding>;
  parentsMapByLabel?: Record<Parent['uuid'], Parent>;
}) => {
  findingsGroupsMap: Record<never, FindingsGroup>;
  findingItemsMap: Record<never, FindingItem | ParentFindingItem>;
  findingsMenu: FindingsMenu;
} = ({ relevantClassifications, irrelevantClassifications, findingsMapByLabel, parentsMapByLabel }) => {
  const relevantFindingsGroups: FindingsGroup[] = [];
  const relevantFindingItems: (FindingItem | ParentFindingItem)[] = [];

  relevantClassifications.slice().forEach(group => {
    const { groupName, displayOrder } = group;

    // Need to refactor this if CXR/CTB models are unified - ideal to switch this based on CXR/CTB study, but this value is not available here
    const isModalityWithChildFindings = 'predictions' in group;
    const findings = isModalityWithChildFindings ? group.predictions : group.findings;

    const relevantGroupFindingItems = getFindingItems.apply(
      this,
      isModalityWithChildFindings ? [findings, findingsMapByLabel, parentsMapByLabel] : [findings, findingsMapByLabel],
    );
    relevantFindingItems.push(...relevantGroupFindingItems);

    const findingsGroup: FindingsGroup = {
      uuid: uuid('findings-group'),
      name: groupName,
      isFirstFindingsGroup: isFirstFindingsGroup(displayOrder),
      findingItemsUuids: relevantGroupFindingItems.map(({ uuid }: { uuid: Uuid<'finding-item'> }) => uuid),
    };
    relevantFindingsGroups.push(findingsGroup);
  });

  const irrelevantFindingItems = getFindingItems(irrelevantClassifications, findingsMapByLabel);
  const irrelevantFindingGroup: FindingsGroup = {
    uuid: uuid('findings-group'),
    name: IRRELEVANT_FINDINGS_TEXT,
    isFirstFindingsGroup: false,
    findingItemsUuids: irrelevantFindingItems.map(({ uuid }: { uuid: Uuid<'finding-item'> }) => uuid),
  };

  const findingItemsMap = createMapFromArrayValues([...relevantFindingItems, ...irrelevantFindingItems]);
  const findingsGroupsMap = createMapFromArrayValues([...relevantFindingsGroups, irrelevantFindingGroup]);

  const findingsMenu: FindingsMenu = {
    groupsUuids: [...relevantFindingsGroups, irrelevantFindingGroup].map(({ uuid }) => uuid),
  };

  return {
    findingItemsMap,
    findingsGroupsMap,
    findingsMenu,
  };
};

export default getFindingsMenu;
