import { PaginatedStudy } from '@annaliseai/api-specifications';
import DateFormats from 'enums/DateFormats';
import formatPatientInformation, { PatientInfoWithTitles } from 'helpers/formatPatientInformation';
import DisplayStudyInformation, { StudyStatus } from 'types/DisplayStudyInformation';
import formatDateTime from './formatDateTime';

const { DISPLAY_FULL_DAY_DATE_12HR_TIME, DISPLAY_24H_TIME, DISPLAY_D_MM_YYYY } = DateFormats;

const formatStudy = (study: PaginatedStudy): DisplayStudyInformation & PatientInfoWithTitles => {
  const {
    accessionNumber,
    type,
    studyInstanceUid,
    createdAt,
    patientId,
    patientName,
    patientBirthDate,
    patientAge,
    patientSex,
    studyDate: studyDateRaw,
    studyTime: studyTimeRaw,
    priority,
  } = study;

  // format time, date, and date/time strings for created date
  const createdDate = createdAt ? formatDateTime(createdAt, DISPLAY_D_MM_YYYY) : '';
  const createdTime = createdAt ? formatDateTime(createdAt, DISPLAY_24H_TIME) : '';
  const createdDateTime = createdAt ? formatDateTime(createdAt, DISPLAY_FULL_DAY_DATE_12HR_TIME) : '';

  // format time, date, and date/time strings for study date
  const studyDate = studyDateRaw ? formatDateTime(studyDateRaw, DISPLAY_D_MM_YYYY) : '';
  // note - prepended dummy date to studyTimeRaw so we can parse it as a date, returning 24-hour time without seconds and without the date
  const studyTime = studyTimeRaw ? formatDateTime(`1000-01-01 ${studyTimeRaw}`, DISPLAY_24H_TIME) : '';
  const studyDateTime =
    studyDateRaw && studyTimeRaw
      ? formatDateTime(`${studyDateRaw} ${studyTimeRaw}`, DISPLAY_FULL_DAY_DATE_12HR_TIME)
      : '';

  // Priority may come to us in KEBAB-CASE, which is not a valid enum key
  const priorityFormatted = priority && priority.replace(/-/, '_');

  const patient = formatPatientInformation({
    patientId,
    patientName,
    patientBirthDate,
    patientAge,
    patientSex,
  });

  return {
    accessionNumber,
    studyInstanceUid,
    studyType: type,
    createdDate,
    createdTime,
    createdDateTime,
    studyDate,
    studyTime,
    studyDateTime,
    priority: priorityFormatted as StudyStatus,
    ...patient,
  };
};

export default formatStudy;
