enum DateFormats {
  DISPLAY_24H_TIME = 'k:mm',
  DISPLAY_12H_TIME = 'hh:mm a',
  DISPLAY_24H_HH_MM_SS = 'k:mm:ss',
  DISPLAY_12H_HH_MM_SS = 'hh:mm:ss a',
  DISPLAY_DAY_DATE_MONTH = 'E, d LLL',
  DISPLAY_D_MM_YYYY = 'd/MM/yyyy',
  DISPLAY_D_MMM_YYYY = 'd MMM yyyy',
  DISPLAY_D_MMMM_YYYY = 'd MMMM yyyy',
  DISPLAY_DD_MMM_YYYY = 'dd MMM yyyy',
  DISPLAY_YYYY_MM_DD = 'yyyy-MM-d',
  DISPLAY_FULL_DAY_DATE_24HR_TIME = 'eeee, d MMMM yyyy k:mm:ss',
  DISPLAY_FULL_DAY_DATE_12HR_TIME = 'eeee, d MMMM yyyy h:mm:ss a',
}

export default DateFormats;
