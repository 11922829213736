import { BASE_WINDOWING, WINDOWING } from '@annaliseai/anna-design-tokens';
import { headIconFront, headIconRight } from '@annaliseai/anna-icons';
import { CtbWindowingType, SegmentLateralityType, StudyType, ViewType } from '@annaliseai/api-specifications';
import FindingPanelHeader from '@annaliseai/finding-panel-header';
import ImageScrollbar from '@annaliseai/image-scrollbar';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import debounce from 'lodash.debounce';
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useIsMounted from 'react-is-mounted-hook';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LoadingIndicator } from 'components';
import windowingButtons from 'constants/ctbWindowing';
import directionsByView from 'constants/directionsByView';
import { CTB_IMAGE_TOOLS } from 'constants/imageTools';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import ToolNames from 'enums/ToolNames';
import trackCtbCornerstoneMouseEvents from 'helpers/analytics/cornerstoneCtbMouseEventTracker';
import { activateTool, addToolsForElement } from 'helpers/cornerstone/cornerstoneToolsHelper';
import ctbToolsUserStateHelper, { CtbToolsStateFinding } from 'helpers/ctb/ctbToolsUserStateHelper';
import { WORKER_MESSAGE } from 'imageQueue/constants';
import { getWorker, updateCachedImages } from 'imageQueue/stateManagers/webWorkerManager';
import selectHasFindingBeenSelectedForStudy from 'selectors/selectHasFindingBeenSelectedForStudy';
import selectCtbViewer from 'selectors/viewer/ctb/selectCtbViewer';
import selectDefaultViews from 'selectors/viewer/ctb/selectDefaultViews';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import selectActiveFindingLaterality from 'selectors/viewer/selectActiveFindingLaterality';
import { selectIsLocalisationToggleOn } from 'slices/viewerSlice';
import CornerstoneEvent from 'types/cornerstone/CornerstoneEvent';
import CornerstoneToolsMouseEvents from 'types/cornerstone/CornerstoneToolsMouseEvents';
import { EventActions, EventCategories, EventNames } from 'types/Matomo';
import Finding from 'types/study/Finding';
import CornerstoneViewport from './CornerstoneViewport';
import WindowingBar, { WindowingContainer } from './WindowingBar.style';

const scrollToIndex = cornerstoneTools.importInternal('util/scrollToIndex');
const { CTB } = StudyType;
const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const { STACK_SCROLL, STACK_SCROLL_MOUSE_WHEEL } = ToolNames;
const { BRAIN } = CtbWindowingType;
const { CTB_VIEWER_CONTAINER } = ReactTestingLibraryDataProperties;
const {
  imageCache: { imageCache },
} = cornerstone;
const { ACTIVE_FINDING } = WORKER_MESSAGE;
const SCROLL_TRACKING_DELAY = 5000;

const CtbViewerContainer = styled.div.attrs({ 'data-testid': CTB_VIEWER_CONTAINER })`
  display: grid;
  grid-template-columns: 20% minmax(0, 1fr) 20%;
  grid-template-rows: minmax(0, 1fr);
  justify-items: center;
  width: 100%;
  height: 100%;
`;

const FindingPanelContainer = styled.div`
  width: fit-content;
  margin-top: 0.5rem;
  margin-bottom: -2.5rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  justify-items: center;

  h2 {
    min-height: 1.5rem;
  }
`;

const LoadingIndicatorContainer = styled.div`
  padding-top: 6rem;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollbarContainer = styled.div`
  height: 100%;
  display: flex;
  min-width: 75px;
  flex-direction: column;
  justify-content: center;
  justify-self: left;
`;

const availableViews = [AXIAL, CORONAL, SAGITTAL] as const;

const CtbViewer: FC = () => {
  const isMounted = useIsMounted();

  const { imageSliceIdsMap, segmentSliceIdsMap } = useSelector(selectCtbViewer);

  const [viewTypeIndex, setViewTypeIndex] = useState(availableViews.findIndex(viewType => viewType === AXIAL));

  const [baseImageIndex, setBaseImageIndex] = useState(0);

  const [windowing, setWindowing] = useState<CtbWindowingType>(BRAIN);
  const [cachedBaseImages, setCachedBaseImages] = useState<number[]>([]);

  // SWV-215 - remember where were the tools left for a finding
  const [ctbToolsUserState, setCtbToolsUserState] = useState<CtbToolsStateFinding[]>([]);

  // image queue - allow local queue to run only after criteria met
  const [canRunLocalImageQueue, setCanRunLocalImageQueue] = useState(false);

  const activeFinding = useSelector(selectActiveFinding);

  const { laterality, hasLocalisation } = useSelector(selectActiveFindingLaterality);
  const isLocalisationToggleOn = useSelector(selectIsLocalisationToggleOn);

  const hasFindingBeenSelectedForStudy = useSelector(selectHasFindingBeenSelectedForStudy);

  const { trackEvent } = useMatomo();

  // reference to reference of the element to be sure that the element is up-to-date
  const baseImageRefRef = useRef<RefObject<HTMLDivElement> | null>(null);
  const stackToolListenersCleanupRef = useRef<() => void>(() => undefined);

  // active view type
  const activeViewType = availableViews[viewTypeIndex];

  // active finding
  const activeFindingName = activeFinding?.name || '';
  const activeFindingUuid = activeFinding?.uuid || undefined;

  // base image
  const baseImageIds = imageSliceIdsMap[activeViewType];
  const baseImageId = baseImageIds[baseImageIndex];
  const imageCacheLength = Object.keys(imageCache).length || 0;

  // segment image
  const segmentViewsByFinding =
    segmentSliceIdsMap && activeFindingUuid && segmentSliceIdsMap[activeFindingUuid as Finding['uuid']];
  const segmentImageIds = segmentViewsByFinding && activeViewType && segmentViewsByFinding[activeViewType];
  const segmentImageId = segmentImageIds && baseImageIndex && segmentImageIds[baseImageIndex];
  const slicesWithSegments = baseImageIds.map((_, index) => (segmentImageIds && segmentImageIds[index] ? 1 : 0));

  const synchroniseScroll = (index: number) => {
    const { current: element } = baseImageRefRef.current || {};
    if (element) {
      scrollToIndex(element, index);
    }
  };

  // tell image queue web worker when the view type, finding or scroll index changes so the
  // web worker can fetch any uncached images requested by the user via the local queue
  useEffect(() => {
    const worker = getWorker();
    canRunLocalImageQueue &&
      worker?.postMessage({
        taskType: ACTIVE_FINDING,
        activeFindingName,
        activeViewType,
        currentIdIndex: baseImageIndex,
      });

    return () => {
      worker?.postMessage({
        taskType: ACTIVE_FINDING,
        currentIdIndex: 0,
      });
    };
  }, [canRunLocalImageQueue, activeViewType, activeFindingName, baseImageIndex]);

  // image queue - allow local queue to run if active finding selected
  useEffect(() => {
    activeFindingName && setCanRunLocalImageQueue(true);
  }, [activeFindingName]);

  // update the scrollbar UI to show cached images
  useEffect(() => {
    setCachedBaseImages(baseImageIds.map(baseImageId => (imageCache[baseImageId] ? 1 : 0)));
  }, [imageCacheLength, baseImageIds]);

  const getMiddleImageForView = (view: ViewType) => {
    return Math.ceil(imageSliceIdsMap[view].length / 2);
  };

  // Jump to key view/slice/window when a finding is active
  // SWV-215 - set the positions for the tools when the finding is selected
  type OnLoadPositionsForCtbToolsProps = {
    window: CtbWindowingType | undefined;
    view: ViewType | undefined;
    selectedImageIndex: number | undefined;
  };

  const setOnLoadPositionsForCtbTools = useCallback(
    ({
      defaultPositions,
      userDefinedPositions,
    }: {
      defaultPositions: OnLoadPositionsForCtbToolsProps;
      userDefinedPositions?: OnLoadPositionsForCtbToolsProps;
    }) => {
      // user defined positions have priority over default positions
      // its setting it twice first default then the user defined, not ideal but doesn't impact performance much
      [defaultPositions, userDefinedPositions].forEach(positions => {
        if (!positions) {
          return;
        }
        if (positions.window) {
          setWindowing(positions.window);
        }
        if (positions.view) {
          setViewTypeIndex(availableViews.findIndex(viewType => viewType === positions.view));
        }
        // selected image 0 is a valid index, so no truthy check
        if (positions.selectedImageIndex !== undefined) {
          setBaseImageIndex(positions.selectedImageIndex);
        }
      });
    },
    [setWindowing, setViewTypeIndex, setBaseImageIndex],
  );

  const defaultPositions = useSelector(selectDefaultViews);
  useEffect(() => {
    // default positions for when no active finding has *ever* been selected
    // are whatever state defaults are and selectedImageIndex: middle image
    if (!hasFindingBeenSelectedForStudy) {
      setOnLoadPositionsForCtbTools({
        defaultPositions: {
          window: windowing,
          view: activeViewType,
          selectedImageIndex: imageSliceIdsMap ? getMiddleImageForView(activeViewType) : 0,
        },
      });
      return;
    }

    if (!activeFinding?.uuid) {
      return;
    }

    const userDefinedPositionsFinding = ctbToolsUserStateHelper({
      state: ctbToolsUserState,
      findingId: activeFinding.uuid,
    }).getFinding();

    const userSelectedImageIndex = ctbToolsUserStateHelper({
      state: ctbToolsUserState,
      findingId: activeFinding.uuid,
    }).getSliderPositionForCurrentView();

    setOnLoadPositionsForCtbTools({
      defaultPositions: {
        window: defaultPositions.window,
        view: defaultPositions.keyView,
        selectedImageIndex: defaultPositions.keyViewSlices?.[activeViewType],
      },
      userDefinedPositions: {
        window: userDefinedPositionsFinding?.selectedWindow as CtbWindowingType,
        view: userDefinedPositionsFinding?.selectedView as ViewType,
        selectedImageIndex: userSelectedImageIndex,
      },
    });
    // reason: we only want this to be triggered when the following properties change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFinding, activeViewType, imageSliceIdsMap]);

  useEffect(() => {
    synchroniseScroll(baseImageIndex);
    // SWV-215 - remember image position
    if (activeFinding?.uuid) {
      const newState = ctbToolsUserStateHelper({
        state: ctbToolsUserState,
        findingId: activeFinding.uuid,
      })
        .setViewSliderPosition({ view: availableViews[viewTypeIndex], position: baseImageIndex })
        .generateNewState();
      setCtbToolsUserState(newState);
    }
    // reason: we only want to run this when baseImageIndex changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseImageIndex]);

  // we debounce tracking, so we don't fire too many events when user
  // uses scrollbar continuously
  const debouncedScrollbarChangeTrackEvent = useMemo(() => {
    const { CTB_VIEWER } = EventCategories;
    const { CTB_SCROLL } = EventActions;
    const { SCROLLBAR } = EventNames;
    return debounce(() => {
      trackEvent({
        category: CTB_VIEWER,
        action: CTB_SCROLL,
        name: SCROLLBAR,
      });
    }, SCROLL_TRACKING_DELAY);
  }, [trackEvent]);

  // early-exit
  const imageSlicesIdMapLoaded =
    imageSliceIdsMap &&
    (!!imageSliceIdsMap[AXIAL].length || !!imageSliceIdsMap[CORONAL].length || !!imageSliceIdsMap[SAGITTAL].length);
  if (!imageSlicesIdMapLoaded) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator show={!imageSlicesIdMapLoaded} />
      </LoadingIndicatorContainer>
    );
  }

  // triggered on bar click and drag
  const imageScrollbarOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const floatValue = parseFloat(event.target.value);
    const value = Math.round(floatValue);
    synchroniseScroll(value);
    //tracking
    debouncedScrollbarChangeTrackEvent();
    // image queue - allow local queue to run if user interacts with scrollbar
    setCanRunLocalImageQueue(true);
  };

  const setupStackTool = (baseImageIndex: number, baseImageIds: string[]) => {
    stackToolListenersCleanupRef.current();

    const { current: element } = baseImageRefRef.current || {};
    if (!element) {
      return;
    }

    const baseStack = {
      currentImageIdIndex: baseImageIndex,
      imageIds: baseImageIds,
      options: {
        name: 'CT',
        opacity: 1,
        viewport: {},
        visible: true,
      },
    };

    cornerstoneTools.clearToolState(element, 'stack');
    cornerstoneTools.addToolState(element, 'stack', baseStack);

    const syncImageIndexes = (event: CornerstoneEvent) => {
      const newImageIndex = event?.detail?.newImageIdIndex;
      if (newImageIndex) {
        setBaseImageIndex(newImageIndex);
      }
    };

    // When a user does a click + drag or mouse wheel scroll on the image to scroll through the list,
    // we will ensure the image index is synced to the thumb position of the scrollbar
    const { CORNERSTONE_TOOLS_MOUSE_WHEEL, CORNERSTONE_TOOLS_MOUSE_DRAG } = CornerstoneToolsMouseEvents;
    const syncImageEvents = [CORNERSTONE_TOOLS_MOUSE_DRAG, CORNERSTONE_TOOLS_MOUSE_WHEEL];
    const debouncedTrackCtbCornerstoneMouseEvents = debounce(trackCtbCornerstoneMouseEvents, SCROLL_TRACKING_DELAY);
    element.addEventListener('cornerstonetoolsstackscroll', syncImageIndexes as EventListener);
    syncImageEvents.forEach(event => {
      element.addEventListener(event, event => {
        // tracking
        debouncedTrackCtbCornerstoneMouseEvents({ eventName: event.type as CornerstoneToolsMouseEvents });
        // image queue - allow local queue to run if user interacts with scrollbar
        setCanRunLocalImageQueue(true);
      });
    });

    stackToolListenersCleanupRef.current = () => {
      element.removeEventListener('cornerstonetoolsstackscroll', syncImageIndexes as EventListener);
      syncImageEvents.forEach(event => {
        // tracking
        element.removeEventListener(event, event => {
          debouncedTrackCtbCornerstoneMouseEvents({ eventName: event.type as CornerstoneToolsMouseEvents });
        });
      });
    };
  };

  const cornerstoneViewportOnInitialised = (elementRef: RefObject<HTMLDivElement>) => {
    const { current: element } = elementRef;
    if (element) {
      baseImageRefRef.current = elementRef;
      addToolsForElement(element, CTB_IMAGE_TOOLS);
      activateTool(element, STACK_SCROLL);
      activateTool(element, STACK_SCROLL_MOUSE_WHEEL);

      cornerstoneTools.addStackStateManager(element, ['stack']);
      setupStackTool(baseImageIndex, baseImageIds);

      const removeUpdateListener = updateCachedImages({
        setCachedBaseImages,
        isMounted: isMounted(),
        imageCache,
        baseImageIds,
      });

      // CornerstoneViewport cleanup
      return () => {
        // We shouldn't clean up element tools here as it is automatically cleared by cornerstone.disable
        stackToolListenersCleanupRef.current();
        // clean up image queue cache updater
        removeUpdateListener();
      };
    }
  };

  const findingPanelHeaderSetViewType = (index: number) => {
    setupStackTool(0, imageSliceIdsMap[availableViews[index]]);

    // if the user switch views and there's no finding selected we select the middle image
    // this prevents an out-of-bounds error when users switches from CORONAL to AXIAL
    if (!activeFinding?.uuid) {
      setBaseImageIndex(getMiddleImageForView(availableViews[index]));
    }

    setViewTypeIndex(index);

    // tracking
    const { CTB_VIEWER } = EventCategories;
    const { CTB_VIEWS } = EventActions;
    trackEvent({
      category: CTB_VIEWER,
      action: CTB_VIEWS,
      name: EventNames[availableViews[index]],
    });

    // SWV-215 - remember view position
    if (activeFinding?.uuid) {
      const newState = ctbToolsUserStateHelper({
        state: ctbToolsUserState,
        findingId: activeFinding.uuid,
      })
        .setView(availableViews[index])
        .generateNewState();
      setCtbToolsUserState(newState);
    }
  };

  const windowingBarChangeWindow = (ctbWindowing: CtbWindowingType) => {
    setWindowing(ctbWindowing);

    const { CTB_VIEWER } = EventCategories;
    const { CTB_WINDOWS } = EventActions;
    trackEvent({
      category: CTB_VIEWER,
      action: CTB_WINDOWS,
      name: EventNames[ctbWindowing],
    });

    // SWV-215 - remember window position
    if (activeFinding?.uuid) {
      const newState = ctbToolsUserStateHelper({
        state: ctbToolsUserState,
        findingId: activeFinding.uuid,
      })
        .setWindow(ctbWindowing)
        .generateNewState();
      setCtbToolsUserState(newState);
    }
  };

  const { windowWidth, windowCenter } = BASE_WINDOWING[windowing];

  const windowingData = {
    windowMetadataWidth: 'W',
    windowWidth,
    windowMetadataLevel: 'L',
    windowLevel: windowCenter,
  };

  const { BILATERAL, RIGHT, LEFT } = SegmentLateralityType;
  const [labelTextTop, labelTextBottom] = directionsByView[activeViewType];
  const shouldHighlightLaterality = activeViewType === SAGITTAL;

  return (
    <Container>
      <FindingPanelContainer>
        <FindingPanelHeader
          activeImageIndex={viewTypeIndex}
          modality={CTB}
          hasLocalisation={hasLocalisation}
          labelName={activeFindingName}
          onImageChange={index => findingPanelHeaderSetViewType(index)}
          laterality={laterality}
          headingFontSize={1.125}
          subheadingFontSize={0.875}
          subHeadingPhrases={{
            lateralityFriendlyNameBilateral: 'Bilateral',
            lateralityFriendlyNameLeft: 'Left',
            lateralityFriendlyNameRight: 'Right',
            subHeadingNoLocalisation: 'No localisation',
          }}
          thumbnailImages={[headIconFront, headIconRight, headIconFront]}
        />
      </FindingPanelContainer>
      <CtbViewerContainer>
        <WindowingContainer>
          <WindowingBar
            windowingButtons={windowingButtons}
            currentWindow={windowing}
            changeWindow={ctbWindowing => windowingBarChangeWindow(ctbWindowing)}
            windowingData={windowingData}
          />
        </WindowingContainer>
        {baseImageId && (
          <CornerstoneViewport
            /* added key prop to rerender component when view has changed (fix issue on Safari) */
            key={activeViewType}
            baseImageId={baseImageId}
            currentView={activeViewType}
            voi={WINDOWING[windowing]}
            isLocalisationVisible={isLocalisationToggleOn}
            laterality={laterality}
            segment={(segmentImageId && { type: 'SEGMENT', value: segmentImageId }) || undefined}
            onInitialised={cornerstoneViewportOnInitialised}
          />
        )}
        <ScrollbarContainer>
          <ImageScrollbar
            cachedBaseImages={cachedBaseImages}
            currentIdIndex={baseImageIndex}
            isBottomLabelActive={shouldHighlightLaterality && (laterality === LEFT || laterality === BILATERAL)}
            isTopLabelActive={shouldHighlightLaterality && (laterality === RIGHT || laterality === BILATERAL)}
            labelTextTop={labelTextTop}
            labelTextBottom={labelTextBottom}
            onChange={imageScrollbarOnChange}
            slicesWithSegments={slicesWithSegments}
            totalSlices={baseImageIds.length}
          />
        </ScrollbarContainer>
      </CtbViewerContainer>
    </Container>
  );
};
export default CtbViewer;
