import React, { FC } from 'react';
import styled from 'styled-components';
import loganColors from 'constants/colors';
import { DisplayFindingGroup } from 'types/finding/FindingGroup';
import FindingGroup, { FindingGroupFindingProps, FindingGroupProps } from './FindingGroup';

const { GREY_3 } = loganColors;

const NO_FINDINGS = 'No findings detected';

export type RelevantFindingGroupsProps = {
  relevantFindingGroups: DisplayFindingGroup[];
  findingProps: FindingGroupFindingProps;
  findingGroupProps: Pick<FindingGroupProps, 'activeFindingId'>;
};

const EmptyList = styled.p`
  margin: 1rem 0 0 2rem;
  padding-left: 2.7rem;
  color: ${GREY_3};
`;

const RelevantFindingGroups: FC<RelevantFindingGroupsProps> = ({
  relevantFindingGroups,
  findingProps,
  findingGroupProps,
}) => {
  if (relevantFindingGroups.length === 0) {
    return <EmptyList>{NO_FINDINGS}</EmptyList>;
  }

  return (
    <>
      {relevantFindingGroups.map(findingGroup => {
        const { uuid } = findingGroup;

        return (
          <FindingGroup
            key={uuid}
            findingGroup={findingGroup}
            isRelevant={true}
            findingProps={findingProps}
            {...findingGroupProps}
          />
        );
      })}
    </>
  );
};

export default RelevantFindingGroups;
