import { StudyType } from '@annaliseai/api-specifications';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { replace } from 'connected-react-router';
import queryString from 'query-string';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Routes from 'enums/Routes';
import { retrieveUploadedStudies } from 'helpers/cookies/uploadStudiesHelper';
import {
  addUploadedStudy,
  deltaInMinutesBetweenFirstAndLastStudy,
  isLoggedIn,
  MAX_FREE_STUDIES,
} from 'helpers/limitationHelper';
import selectFindingsMap from 'selectors/viewer/selectFindingsMap';
import { selectStudyIds } from 'selectors/viewer/selectStudy';
import { RootState } from 'slices/RootState';
import { EventActions, EventCategories } from 'types/Matomo';

const { CXR_SAMPLE, VIEWER, STAGE } = Routes;
const { FINDINGS_COUNTER } = ReactTestingLibraryDataProperties;
const { WEEKLY_SCAN_LIMIT } = EventCategories;
const { HIT } = EventActions;
const { CXR } = StudyType;

const Container = styled.section`
  display: grid;
`;

const BodyContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
  height: 13rem;
`;

const FindingsCounter = styled.p.attrs({ 'data-testid': FINDINGS_COUNTER })`
  font-size: 3em;
  font-weight: 300;
`;

const Label = styled.label`
  margin-top: 0.9em;
  font-size: 1em;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const ProgressScreen: FC = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const findingsCompleted = useRef(0);

  const studyIds = useSelector(selectStudyIds);
  const findingsMap = useSelector(selectFindingsMap);
  const { error } = useSelector(({ error }: RootState) => error);
  const { files } = useSelector(({ file }: RootState) => file);

  const { trackEvent } = useMatomo();

  useEffect(() => {
    let timeout: number;

    if (progress < 100) {
      timeout = setTimeout(() => {
        // getting to 100 but never reach 100
        setProgress(progress => progress + (2 * (100 - progress)) / 100);

        // progress expressed in relation to 124 findings
        findingsCompleted.current = Math.floor(progress * 1.24);
      }, 100);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [progress, setProgress]);

  const onUploadNavigateTimeout = useRef(0);

  const hasFindings = findingsMap && Object.keys(findingsMap).length > 0;
  const isLoaded = !!(studyIds && hasFindings);

  useEffect(() => {
    if (isLoaded) {
      setProgress(100);
      findingsCompleted.current = 124;

      // some delay effect for the progress bar to finish
      onUploadNavigateTimeout.current = setTimeout(() => {
        const { accessionNumber, studyInstanceUid } = studyIds;
        const query = queryString.stringify({
          accessionNumber,
          studyInstanceUid,
        });
        if (!isLoggedIn()) {
          addUploadedStudy({ type: CXR, accessionNumber, createdAt: new Date(), studyInstanceUid, patientId: '' });
          const storedStudies = retrieveUploadedStudies();
          if (storedStudies.length >= MAX_FREE_STUDIES) {
            trackEvent({
              category: WEEKLY_SCAN_LIMIT,
              action: HIT,
              name: 'delta_in_minutes',
              value: deltaInMinutesBetweenFirstAndLastStudy(storedStudies),
            });
          }
        }
        dispatch(replace(`${VIEWER}?${query}`));
      }, 2000);
    }

    return () => {
      clearTimeout(onUploadNavigateTimeout.current);
    };
  }, [studyIds, isLoaded, setProgress, dispatch, trackEvent]);

  useEffect(() => {
    if (error) {
      clearTimeout(onUploadNavigateTimeout.current);
      if (isLoggedIn()) {
        dispatch(replace(STAGE));
      } else {
        dispatch(replace(CXR_SAMPLE));
      }
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (files.length < 1) {
      dispatch(replace(STAGE));
    }
  }, [files, dispatch]);

  return (
    <Container>
      <BodyContainer>
        <ProgressContainer>
          <FindingsCounter>
            {findingsCompleted.current} / <Bold>124</Bold>
          </FindingsCounter>
          <ProgressBar progress={progress} />
          <Label>Searching for findings…</Label>
        </ProgressContainer>
      </BodyContainer>
    </Container>
  );
};

export default ProgressScreen;
