import { GetOrgStudiesRes, GetStudiesSearchRes } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import configuration from 'configuration';
import { StudyListState } from 'slices/studyListSlice';

const getStudies = async (params?: StudyListState['query']): Promise<GetStudiesSearchRes & GetOrgStudiesRes> => {
  const { isViewer } = configuration;

  const { studies, studiesSearch } = endpoints.v1;
  const path = isViewer ? studiesSearch.getPath() : studies.getPath();
  const { data } = isViewer ? await client.get(path, { params }) : await client.get(path, {});

  log.debug('received getStudies response=', data);
  return data;
};

export default getStudies;
