import axios from 'axios';
import cancelInvalidRequests from 'api/client/cancelInvalidRequests';
import configuration from 'configuration';
import addSigningHeaders from './addSigningHeaders';
import verifyResponse from './verifyResponse';

export const baseURL = configuration.apiUrl;

const instance = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    Accept: 'application/json; charset=UTF-8',
    'Content-Type': 'application/json; charset=UTF-8',
    'x-annalise-ai-app-version': 'logan-0.1.0',
  },
});

axios.interceptors.response.use(verifyResponse);

instance.interceptors.request.use(cancelInvalidRequests);
instance.interceptors.request.use(addSigningHeaders);
instance.interceptors.response.use(verifyResponse);

export default instance;
