import { colors } from '@annaliseai/anna-design-tokens';
import { isoAlert } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import styled from 'styled-components';
import configuration from 'configuration';
import {
  DISCLAIMER_TITLE_DEMO,
  DISCLAIMER_TITLE_VIEWER,
  DISCLAIMER_TEXT_DEMO,
  DISCLAIMER_TEXT_VIEWER,
} from 'constants/index';
import { APP } from 'constants/sizes';

const { DISCLAIMER_HEIGHT } = APP;
const { BUTTON_SECONDARY_TEXT } = colors;

const Container = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.75;
  height: ${DISCLAIMER_HEIGHT}rem;
  color: ${BUTTON_SECONDARY_TEXT};
`;

const TextContainer = styled.div`
  display: flex;
  align-self: center;
  padding-left: 0.5rem;
  font-size: 0.69em;
  letter-spacing: 0.05em;
  font-weight: 300;
`;

const Disclaimer: FC = () => {
  const { isDemo, isViewer } = configuration;
  const disclaimerTitle: string = (isDemo && DISCLAIMER_TITLE_DEMO) || (isViewer && DISCLAIMER_TITLE_VIEWER) || '';

  const disclaimerText: string = (isDemo && DISCLAIMER_TEXT_DEMO) || (isViewer && DISCLAIMER_TEXT_VIEWER) || '';

  return (
    <Container title={disclaimerTitle}>
      <IconWrapper svg={isoAlert} size={1.3} />
      <TextContainer>{disclaimerText}</TextContainer>
    </Container>
  );
};

export default Disclaimer;
