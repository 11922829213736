enum DisplayErrorLastParagraph {
  ANOTHER_FILE = 'Please try uploading another file.',
  ANOTHER_STUDY = 'Please try uploading another study.',
  DIFFERENT_STUDY = 'Please select a different study.',
  REDIRECTED = 'You will now be redirected.',
  REFRESH_PAGE = 'Please wait a moment and refresh the page or reselect the study.',
  SUPPORT = 'If the situation persists',
  MORE_UPLOADS_SUPPORT = 'To request more uploads',
}

export default DisplayErrorLastParagraph;
