import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const About: FC<Icon> = ({ size = 1.5, color = colors.WHITE }) => (
  <Container size={size} testId={ReactTestingLibraryDataProperties.ICON_ABOUT}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M10 1.664a8.335 8.335 0 110 16.67 8.335 8.335 0 010-16.67zm0 1.25a7.085 7.085 0 100 14.17 7.085 7.085 0 000-14.17zm-.003 5.834c.316 0 .578.235.62.54l.005.084.003 4.585a.625.625 0 01-1.244.086l-.006-.085-.003-4.585c0-.345.28-.625.625-.625zM10 5.833a.832.832 0 110 1.665.832.832 0 010-1.665z"
        fill={color}
        fillOpacity=".75"
      />
    </svg>
  </Container>
);

export default About;
