import { css, FlattenSimpleInterpolation } from 'styled-components';

export const TableCellCss = (): FlattenSimpleInterpolation => css`
  margin: 0;
  padding: 0.5em;
  height: 2.5em;
`;

export const PaginationIndexCss = (): FlattenSimpleInterpolation => css`
  margin: 0;
  padding: 4px 8px;

  // making it a nice square
  display: grid;
  place-items: center;
`;
