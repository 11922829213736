import { SegmentLateralityType } from '@annaliseai/api-specifications';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LoadingIndicator } from 'components';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import SmallHeaderContainer from 'layouts/SmallHeaderContainer';
import selectActiveFindingSegment from 'selectors/viewer/cxr/selectActiveFindingSegment';
import selectActiveFindingViews from 'selectors/viewer/cxr/selectActiveFindingViews';
import selectActiveImage from 'selectors/viewer/cxr/selectActiveImage';
import selectActiveImageMetadata from 'selectors/viewer/cxr/selectActiveImageMetadata';
import selectCxrViewer from 'selectors/viewer/cxr/selectCxrViewer';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import selectImagesMap from 'selectors/viewer/selectImagesMap';
import selectLocalisationsMap from 'selectors/viewer/selectLocalisationsMap';
import CxrImage from './CxrImage';
import CxrStudyResultThumbnails from './CxrStudyResultThumbnails';

const { HEADER_HEIGHT, VIEWER_HORIZONTAL_PADDING } = APP;
const { FINDING_NAME_CONTAINER, STUDY_RESULT } = ReactTestingLibraryDataProperties;
const { BILATERAL, RIGHT, LEFT } = SegmentLateralityType;

const Container = styled.div.attrs({ 'data-testid': STUDY_RESULT })`
  position: relative;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  place-items: center;
`;

const HeaderContainer = styled(SmallHeaderContainer)`
  display: flex;
  font-size: 1.125rem;
  height: ${HEADER_HEIGHT}rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const FindingNameContainer = styled.div.attrs({
  'data-testid': FINDING_NAME_CONTAINER,
})``;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ImageContainerPreLoad = styled(ImageContainer)`
  position: relative;
  margin-left: ${VIEWER_HORIZONTAL_PADDING}rem;
  margin-top: 2.5rem;
`;

const getLaterality = (laterality?: SegmentLateralityType): string => {
  switch (laterality) {
    case BILATERAL:
      return ' (bilateral)';
    case RIGHT:
      return ' (right)';
    case LEFT:
      return ' (left)';
    default:
      return '';
  }
};

const CxrStudyResult: FC = () => {
  const { activeView } = useSelector(selectCxrViewer);
  const localisationsMap = useSelector(selectLocalisationsMap);
  const imagesMap = useSelector(selectImagesMap);
  const activeFinding = useSelector(selectActiveFinding);
  const activeImage = useSelector(selectActiveImage);
  const activeImageMetadata = useSelector(selectActiveImageMetadata);
  const findingViews = useSelector(selectActiveFindingViews) || [];
  const activeFindingSegment = useSelector(selectActiveFindingSegment);

  if (Object.keys(imagesMap).length < 1) {
    return (
      <ImageContainerPreLoad>
        <LoadingIndicator />
      </ImageContainerPreLoad>
    );
  }

  const activeViewData = findingViews?.find(({ uuid }) => uuid === activeView);
  const localisation = activeViewData?.localisationUuid && localisationsMap[activeViewData.localisationUuid];

  return (
    <Container>
      <HeaderContainer>
        <FindingNameContainer>{`${activeFinding?.name || ''}${
          localisation?.type === 'LATERALITY' ? getLaterality(localisation.laterality) : ''
        }`}</FindingNameContainer>
        <CxrStudyResultThumbnails views={findingViews} activeViewData={activeViewData} localisation={localisation} />
      </HeaderContainer>
      <ImageContainer>
        {activeImage && activeImageMetadata && (
          <CxrImage
            sourceUrl={activeImage.url}
            sourceMetadata={activeImageMetadata}
            segment={{
              url: activeFindingSegment,
              laterality: localisation?.type === 'LATERALITY' ? localisation?.laterality : undefined,
            }}
          />
        )}
      </ImageContainer>
    </Container>
  );
};

export default CxrStudyResult;
