import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Disclaimer } from 'components';
import Header from 'components/Header';
import PrimaryButton from 'components/PrimaryButton';
import WorklistTable from 'components/WorklistTable';
import { BREAK_POINTS } from 'constants/sizes';
import Routes from 'enums/Routes';
import { FooterContainer } from 'layouts/ResponsiveContainers';
import { authActions } from 'slices/authSlice';

const { LOGIN } = Routes;
const { MEDIUM_SCREEN_WIDTH } = BREAK_POINTS;

const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0 0;
  grid-template-areas:
    'nav'
    'main';
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: main;
  @media (min-width: ${MEDIUM_SCREEN_WIDTH}px) {
    padding: 0 8rem;
  }
`;

const SignOutButtonContainer = styled.div``;

const Worklist = (): JSX.Element => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(authActions.logout({ redirect: LOGIN }));
  };

  return (
    <Container>
      <Header hasSearchBar>
        <SignOutButtonContainer>
          <PrimaryButton onClick={signOut}>Sign out</PrimaryButton>
        </SignOutButtonContainer>
      </Header>
      <BodyContainer>
        <WorklistTable />
      </BodyContainer>
      <FooterContainer>
        <Disclaimer />
      </FooterContainer>
    </Container>
  );
};

export default Worklist;
