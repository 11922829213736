enum Routes {
  LOGIN = '/login',
  VIEWER = '/viewer',
  LIST = '/list',
  STAGE = '/stage',
  HOME = '/',
  PROGRESS = '/progress',
  USER_INFORMATION = '/user-information',
  CXR_SAMPLE = '/cxr',
  CTB_SAMPLE = '/ctb',
}

export default Routes;
