import styled from 'styled-components';

const SmallHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
  min-height: 4rem;
`;

export default SmallHeaderContainer;
