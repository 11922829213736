import './normalize.css';
import './tailwind-preflight.css';
import './index.css';

// Initialize cornerstone in the app root to be sure that all cornerstone libraries are set up before importing
import './helpers/cornerstone/initializeCornerstone';

import { createInstance, MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { ConnectedRouter } from 'connected-react-router';
import log from 'loglevel';
import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import configuration from 'configuration';
import { isLoggedIn } from 'helpers/limitationHelper';
import useStrings from 'hooks/useStrings';
import Worklist from 'screens/Worklist';
import history from './browserHistory';
import CookiesToggle from './components/CookiesToggle';
import ErrorModal from './components/ErrorModal';
import SmallScreenBlockingModal from './components/SmallScreenBlockingModal';
import UserInformationCheck from './components/UserInformationCheck';
import Routes from './enums/Routes';
import getMatomoEnv from './helpers/analytics/getMatomoEnvironment';
import useNavigationTracker from './helpers/analytics/useNavigationTracker';
import PublicLayout from './layouts/PublicLayout';
import PrivateRoute from './routes/PrivateRoute';
import CtbSampleScreen from './screens/CtbSampleScreen';
import CxrSampleScreen from './screens/CxrSampleScreen';
import DemoScreen from './screens/DemoScreen';
import ListScreen from './screens/ListScreen';
import LoginScreen from './screens/LoginScreen';
import ProgressScreen from './screens/ProgressScreen';
import StageScreen from './screens/StageScreen';
import UserInformationScreen from './screens/UserInformationScreen';
import ViewerScreen from './screens/ViewerScreen';
import store from './store';

const { HOME, LOGIN, PROGRESS, USER_INFORMATION, VIEWER, LIST, STAGE, CXR_SAMPLE, CTB_SAMPLE } = Routes;

log.setDefaultLevel('warn');

const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  section {
    width: 100%;
    color: white;
    background-color: black;
  }
`;

const { isDemo, isViewer } = configuration;

const App: FC = () => {
  const { enableLinkTracking } = useMatomo();

  const { TITLE, TITLE_LOGGED_IN } = useStrings();
  const isUserLoggedIn = isLoggedIn();
  enableLinkTracking();
  useNavigationTracker();

  useEffect(() => {
    if (TITLE && TITLE_LOGGED_IN) {
      const title = isLoggedIn() ? TITLE_LOGGED_IN : TITLE;
      document.title = title;
      document.querySelector('meta[name="description"]')?.setAttribute('content', title);
    }
  }, [isUserLoggedIn, TITLE, TITLE_LOGGED_IN]);

  return (
    <AppContainer>
      {/* SHARED ROUTES */}
      <Switch>
        <Route path={LOGIN}>
          <LoginScreen />
        </Route>
      </Switch>

      {/* PUBLIC DEMO ROUTES */}
      {isDemo && (
        <Switch>
          <Route exact path={HOME}>
            <PublicLayout>
              <DemoScreen />
            </PublicLayout>
          </Route>
          <Route exact path={CXR_SAMPLE}>
            <PublicLayout>
              <CxrSampleScreen />
            </PublicLayout>
          </Route>
          <Route exact path={CTB_SAMPLE}>
            <PublicLayout>
              <CtbSampleScreen />
            </PublicLayout>
          </Route>
          <Route path={VIEWER}>
            <UserInformationCheck>
              <ViewerScreen />
            </UserInformationCheck>
          </Route>
          <Route path={PROGRESS}>
            <PublicLayout>
              <ProgressScreen />
            </PublicLayout>
          </Route>
          <Route path={USER_INFORMATION}>
            <PublicLayout>
              <UserInformationScreen />
            </PublicLayout>
          </Route>

          {/*PRIVATE DEMO ROUTES*/}
          <PrivateRoute path={LIST}>
            <ListScreen />
          </PrivateRoute>
          <PrivateRoute path={STAGE}>
            <StageScreen />
          </PrivateRoute>
        </Switch>
      )}

      {/*VIEWER ROUTES - ALL PRIVATE*/}
      {isViewer && (
        <Switch>
          <PrivateRoute exact path={HOME}>
            <Worklist />
          </PrivateRoute>
          <PrivateRoute path={VIEWER}>
            <ViewerScreen />
          </PrivateRoute>
        </Switch>
      )}

      <ErrorModal />
      <SmallScreenBlockingModal />
      <CookiesToggle />
    </AppContainer>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MatomoProvider value={createInstance(getMatomoEnv())}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </MatomoProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
