import { AppStrings } from 'constants/strings/strings.types';

const strings: AppStrings = {
  demo: {
    TITLE: 'Annalise Demo',
    TITLE_LOGGED_IN: 'Annalise Private Demo',
  },
  viewer: {
    TITLE: 'Annalise Web Viewer beta',
    TITLE_LOGGED_IN: 'Annalise Web Viewer beta',
  },
};

export default strings;
