import React, { FC } from 'react';
import styled from 'styled-components';
import getPaginationRange from 'helpers/getPaginationRange';
import PaginationCurrentDiv from './PaginationCurrentDiv';
import PaginationNumberButton from './PaginationNumberButton';

type PaginationIndexesProps = {
  currentPageIndex: number;
  pageCount: number;
  range?: number;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
};

type GridProps = {
  range: number;
};

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${({ range }) => range}, 1fr);
  justify-content: center;
`;

const PaginationIndexes: FC<PaginationIndexesProps> = ({ currentPageIndex, gotoPage, pageCount, range = 11 }) => {
  const paginationRange = getPaginationRange(currentPageIndex, range, pageCount);
  return (
    <Grid range={paginationRange.length}>
      {paginationRange.map(pageIndex => {
        if (pageIndex === currentPageIndex)
          return (
            <PaginationCurrentDiv key={`paginationRange${currentPageIndex}${pageIndex}`}>
              {pageIndex}
            </PaginationCurrentDiv>
          );
        return (
          <PaginationNumberButton
            key={`paginationRange${currentPageIndex}${pageIndex}`}
            onClick={() => gotoPage(pageIndex - 1)}
          >
            {pageIndex}
          </PaginationNumberButton>
        );
      })}
    </Grid>
  );
};

export default PaginationIndexes;
