import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FetchStates from 'enums/FetchStates';
import { retainValidParams } from 'helpers/queryParams';
import DisplayStudyInformation from 'types/DisplayStudyInformation';

const { INITIAL, SENDING, FAILED, FULFILLED } = FetchStates;

export type Direction = 'asc' | 'desc';

export type StudyListState = {
  fetchState: FetchStates;
  studies?: DisplayStudyInformation[];
  totalRecords?: number;
  lastFetched?: Date;
  query: {
    createdAfter?: string;
    createdUntil?: string;
    direction?: Direction;
    limit?: number;
    page?: number;
    requestingService?: string;
    search?: string;
    sortBy?: string;
  };
};

type FetchedStudies = {
  studies: StudyListState['studies'];
  totalRecords?: StudyListState['totalRecords'];
};

export const initialState: StudyListState = {
  fetchState: INITIAL,
  query: {
    direction: 'asc',
    limit: 10,
    page: 1,
  },
};

const updateQuery = (oldQuery: StudyListState['query'], newQuery: StudyListState['query']) => ({
  query: retainValidParams({
    ...oldQuery,
    ...newQuery,
  }),
});

export const studyListSlice = createSlice({
  name: 'studyList',
  initialState,
  reducers: {
    fetch: (state: StudyListState, { payload }: PayloadAction<StudyListState['query'] | undefined>) => ({
      ...state,
      payload,
      fetchState: SENDING,
    }),
    fetchFailed: state => ({ ...state, fetchState: FAILED }),
    setStudies: (state, { payload }: PayloadAction<FetchedStudies>) => ({
      ...state,
      fetchState: FULFILLED,
      studies: payload.studies,
      totalRecords: payload.totalRecords,
      lastFetched: new Date(),
    }),
    resetStudies: _ => ({ ...initialState }),
    setQuery: (state, { payload }: PayloadAction<StudyListState['query']>) => ({
      ...state,
      ...updateQuery(state.query, payload),
    }),
    resetQuery: state => ({
      ...state,
      query: {},
    }),
  },
});

export const studyListActions = studyListSlice.actions;
export type FetchType = ReturnType<typeof studyListActions.fetch>;
export type FetchFailedType = ReturnType<typeof studyListActions.fetchFailed>;
export type SetStudiesType = ReturnType<typeof studyListActions.setStudies>;
export type ResetStudiesType = ReturnType<typeof studyListActions.resetStudies>;
export type SetQueryType = ReturnType<typeof studyListActions.setQuery>;
export type ResetQueryType = ReturnType<typeof studyListActions.resetQuery>;
export type StudyListActionTypes = SetStudiesType | ResetStudiesType | FetchType | FetchFailedType;
