import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import Routes from 'enums/Routes';
import { retrieveCredential } from 'helpers/cookies/credentialHelper';
import { isLoggedIn } from 'helpers/limitationHelper';
import getPath from 'helpers/router/getPath';
import { authActions } from 'slices/authSlice';
import LocationState from 'types/LocationState';

type PrivateRouteProps = RouteProps & { path: string };

const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { clientId, clientSecret } = retrieveCredential();
  // cookie credentials are set after successful login and removed when expired
  const validCookie = isLoggedIn();

  const dispatch = useDispatch();
  const location = useLocation();
  const { path } = rest;

  useEffect(() => {
    if (validCookie) {
      dispatch(authActions.login({ clientId, clientSecret }));
    } else {
      if (path !== Routes.LOGIN) {
        dispatch(
          push<LocationState>(Routes.LOGIN, {
            redirect: getPath(location),
          }),
        );
      } else {
        dispatch(push(Routes.LOGIN));
      }
    }
  }, [dispatch, location, path, validCookie, clientId, clientSecret]);

  return (
    <Route
      {...rest}
      render={() => {
        if (validCookie) {
          return children;
        }
      }}
    />
  );
};

export default PrivateRoute;
