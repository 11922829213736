import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const Studies: FC<Icon> = ({ size = 1.5, color = colors.WHITE }) => (
  <Container size={size} testId={ReactTestingLibraryDataProperties.ICON_STUDIES}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M9.375 6.667h6.25l.2.007a2.709 2.709 0 012.504 2.548l.004.153v6.25a2.709 2.709 0 01-2.554 2.704l-.154.005h-6.25a2.708 2.708 0 01-2.704-2.555l-.004-.154v-6.25A2.708 2.708 0 019.22 6.671zm-1.458 8.958l.003.106.016.13a1.46 1.46 0 001.32 1.218l.119.005h6.25c.765 0 1.393-.59 1.454-1.34l.004-.119v-6.25c0-.805-.653-1.458-1.458-1.458h-6.25c-.765 0-1.393.59-1.454 1.339l-.004.12zm5.208-11.457a2.71 2.71 0 012.5 1.665h-1.482a1.453 1.453 0 00-.898-.41l-.12-.005h-6.25c-.765 0-1.393.59-1.454 1.339l-.004.12v6.25l.009.162c.037.333.185.631.407.858v1.48a2.71 2.71 0 01-1.662-2.347l-.004-.154v-6.25A2.708 2.708 0 016.72 4.172l.154-.004zm-2.5-2.5c1.083 0 2.018.636 2.451 1.555l.049.112-1.48-.002a1.454 1.454 0 00-.9-.41l-.12-.005h-6.25c-.765 0-1.393.59-1.454 1.339l-.004.12v6.25c0 .397.159.757.416 1.02v1.48a2.71 2.71 0 01-1.662-2.347l-.004-.154v-6.25A2.708 2.708 0 014.22 1.672l.154-.004z"
        fill={color}
        fillOpacity=".75"
      />
    </svg>
  </Container>
);

export default Studies;
