import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Disclaimer } from 'components';
import Header from 'components/Header';
import LightButton from 'components/LightButton';
import TextField from 'components/TextField';
import configuration from 'configuration';
import colors from 'constants/colors';
import { LINK_PRIVACY_POLICY } from 'constants/links';
import { LoginIntroText, ModeText } from 'enums';
import { isLoginSending } from 'enums/LoginStates';
import Routes from 'enums/Routes';
import useRedirectIfLoggedIn from 'hooks/useRedirectIfLoggedIn';
import { FooterContainer } from 'layouts/ResponsiveContainers';
import { authActions } from 'slices/authSlice';
import { RootState } from 'slices/RootState';
import LocationState from 'types/LocationState';

const { LIST, HOME } = Routes;
const { INTRO_DEMO_TEXT, INTRO_VIEWER_TEXT } = LoginIntroText;
const { DEMO_MODE_TEXT, VIEWER_MODE_TEXT } = ModeText;
const { PURPLE_3 } = colors;

const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

const BodyBackground = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: var(--login-screen__body--background);
  background-image: url('/static/images/background.jpg');
  background-size: cover;
  background-position: 50%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto 8em;
  height: 21rem;
`;

const Intro = styled.div`
  font-size: 3rem;
  width: 48rem;
  color: #d6cfef;
  text-shadow: 0.06rem 0.06rem 0rem ${PURPLE_3};
`;

type FormContainerProps = {
  disabled: boolean;
};

const FormContainer = styled.form<FormContainerProps>`
  display: flex;
  ${({ disabled }) => (disabled ? 'opacity: 0.7;' : '')}
`;

const TextFieldContainer = styled.div`
  height: 3rem;
  line-height: 3rem;
  width: 24rem;
  margin-right: 1rem;
`;

const ButtonContainer = styled.div`
  height: 3rem;
  min-width: 6rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 1rem;
  height: 7rem;
  width: 100%;
`;

const PrivacyPolicy = styled.a`
  margin-right: 4rem;
  text-decoration: none;
  color: white;
`;

const LoginScreen: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const { isDemo, isViewer } = configuration;

  useRedirectIfLoggedIn();

  const { loginState } = useSelector(({ auth }: RootState) => auth);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    let redirect: string = HOME;
    if (location.state) {
      redirect = location.state.redirect;
    }
    if (configuration.isDemo) {
      redirect = LIST;
    }
    if (configuration.isViewer) {
      redirect = HOME;
    }

    dispatch(authActions.login({ clientId, clientSecret, redirect }));
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientId(event.target.value.trim());
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientSecret(event.target.value.trim());
  };

  const introText = (isDemo && INTRO_DEMO_TEXT) || (isViewer && INTRO_VIEWER_TEXT);
  const footerText = (isDemo && DEMO_MODE_TEXT) || (isViewer && VIEWER_MODE_TEXT);

  return (
    <Container>
      <Header />
      <BodyBackground>
        <BodyContainer>
          <Intro>{introText}</Intro>
          <FormContainer onSubmit={onSubmit} disabled={isLoginSending(loginState)}>
            <TextFieldContainer>
              <TextField
                autoComplete="off"
                type="text"
                placeholder="Username"
                onChange={onUsernameChange}
                id="clientId"
                disabled={isLoginSending(loginState)}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                autoComplete="off"
                type="password"
                placeholder="Password"
                onChange={onPasswordChange}
                id="clientSecret"
                disabled={isLoginSending(loginState)}
              />
            </TextFieldContainer>
            <ButtonContainer>
              <LightButton type="submit" disabled={!clientId || !clientSecret || isLoginSending(loginState)}>
                Log in
              </LightButton>
            </ButtonContainer>
          </FormContainer>
        </BodyContainer>
      </BodyBackground>

      <Footer>
        <div>
          <PrivacyPolicy href={LINK_PRIVACY_POLICY}>Privacy Policy</PrivacyPolicy>
          <span>© {new Date().getFullYear()} Annalise</span>
        </div>
        <span>{footerText}</span>
      </Footer>
      <FooterContainer>
        <Disclaimer />
      </FooterContainer>
    </Container>
  );
};

export default LoginScreen;
