import CustomErrors from 'enums/CustomErrors';
import { ConfidenceIndicatorValues } from 'types/ConfidenceIndicatorValues';
import CustomError from './error/CustomError';

const { THRESHOLD_NOT_SCALABLE } = CustomErrors;

// eslint-disable-next-line import/prefer-default-export
export const scaleConfidenceIndicatorValues = ({
  confidence,
  threshold,
  probability,
}: ConfidenceIndicatorValues): ConfidenceIndicatorValues => {
  if (threshold >= 1) {
    throw new CustomError(THRESHOLD_NOT_SCALABLE);
  }

  const scaledThreshold = 0.5;
  const scaledProbability = (scaledThreshold * (probability - threshold)) / (1 - threshold) + scaledThreshold;
  let scaledConfidence = (scaledThreshold * confidence) / (1 - threshold);

  if (scaledProbability + scaledConfidence > 1) {
    scaledConfidence = 1 - scaledProbability;
  }

  return {
    confidence: scaledConfidence,
    probability: scaledProbability,
    threshold: scaledThreshold,
  };
};
