// Those are the colors that are currently not in anna
// TODO: take ALL these colours from @annaliseai/anna-design-tokens when available
const colors = {
  BLACK: '#000',
  CHARCOAL: '#191919',
  DARK_7: '#444',
  GREY_2: '#333',
  GREY_6: '#808080',
  HAITI: '#1b1342',
  WHITE_15: 'rgba(255, 255, 255, 0.15)',
  WHITE_20: 'rgba(255, 255, 255, 0.20)',
  GREY_3: '#999',
  GREY_4: '#666',
  GREY_HOME: '#aaa',
  GREY_SEPARATOR: 'rgba(217, 217, 217, 0.2)',
  LOCALISATION_OVERLAY: 'rgba(219, 0, 255, 0.35)',
  PURPLE_1: '#5436bd',
  BACKGROUNDS_WINDOW: '#1e1e1e',
  SIDEBAR: '#1a202c',
  CURRENT_COLOR: 'currentColor',
  RED_1: '#FF797E',
  YELLOW_1: '#FFE483',
  AQUA_1: '#A5E1FF',
  PURPLE_3: '#352685',
};

export default colors;
