import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const Help: FC<Icon> = ({ size = 1.5, color = colors.WHITE, fillOpacity = 1 }) => (
  <Container size={size} testId={ReactTestingLibraryDataProperties.ICON_HELP}>
    <svg fill="none" height="100%" viewBox="0 0 20 20" width="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m9.99984 1.66602c4.60246 0 8.33336 3.73166 8.33336 8.33333 0 4.60165-3.7309 8.33335-8.33336 8.33335-4.6025 0-8.33334-3.7317-8.33334-8.33335 0-4.60167 3.73084-8.33333 8.33334-8.33333zm0 1.38916c-3.82917 0-6.94417 3.115-6.94417 6.94417 0 3.82915 3.115 6.94415 6.94417 6.94415 3.82916 0 6.94416-3.115 6.94416-6.94415 0-3.82917-3.115-6.94417-6.94416-6.94417zm0 9.86082c.46026 0 .83336.3731.83336.8333 0 .4603-.3731.8334-.83336.8334-.46024 0-.83334-.3731-.83334-.8334 0-.4602.3731-.8333.83334-.8333zm0-7.29165c1.26566 0 2.29166 1.02601 2.29166 2.29167 0 .84229-.2479 1.3117-.876 1.96548l-.1404.1431c-.5182.5183-.6503.7383-.6503 1.2247 0 .3452-.2798.625-.62496.625-.34518 0-.625-.2798-.625-.625 0-.8422.24795-1.31165.87596-1.96544l.1404-.14312c.5183-.51825.6503-.73829.6503-1.22472 0-.5753-.4664-1.04167-1.04166-1.04167-.53934 0-.98295.40989-1.03629.93516l-.00538.10651c0 .34517-.27982.625-.625.625s-.625-.27983-.625-.625c0-1.26566 1.02602-2.29167 2.29167-2.29167z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default Help;
