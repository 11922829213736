import styled from 'styled-components';
import PrimaryButton from 'components/PrimaryButton';

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  align-self: center;
  padding: 0 0.5rem;
  font-weight: 300;
`;

export const WorklistPaginationButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  width: 9rem;
  height: 2.5rem;
`;
