import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';
import onboardingContent from 'constants/onboardingContent';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import OnboardCard from './OnboardCard';
import TransparentButton from './TransparentButton';

const { ONBOARDING_SIDEBAR_CONTAINER } = ReactTestingLibraryDataProperties;
const { SIDEBAR_WIDTH } = APP;

const Container = styled.div.attrs({ 'data-testid': ONBOARDING_SIDEBAR_CONTAINER })`
  width: ${SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #5436bd;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 2.5em;
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

const Button = styled(TransparentButton)`
  height: 3em;
  font-weight: 500;
  font-size: 0.9em;
  border-radius: 0.5em;
`;

type OnboardingSidebarProps = {
  onClick: () => void;
};

const OnboardingSidebar: FC<OnboardingSidebarProps> = ({ onClick }) => (
  <Container>
    {onboardingContent.map(cardValues => (
      <OnboardCard {...cardValues} key={cardValues.title} />
    ))}
    <ButtonContainer>
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} /> Show Annalise findings
      </Button>
    </ButtonContainer>
  </Container>
);

export default OnboardingSidebar;
