import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Z_INDEXES from 'constants/zIndexes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Warning from 'icons/Warning';
import Overlay from './Overlay';
import PrimaryButton from './PrimaryButton';

const { MODAL_CONTAINER, MODAL_OVERLAY } = ReactTestingLibraryDataProperties;
const { MODAL } = Z_INDEXES;

const ModalContainer = styled.div.attrs({ 'data-testid': MODAL_CONTAINER })`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 0;
  background: rgba(27, 27, 27, 0.9);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  z-index: ${MODAL};
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  svg {
    display: inline;
    vertical-align: baseline;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin: 1em;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  margin: 1em auto;
`;

const ModalHeader = styled.div`
  background: linear-gradient(0deg, rgba(27, 27, 27, 0.75), rgba(27, 27, 27, 0.75));
  min-height: 3rem;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

const OKButton = styled(PrimaryButton)`
  height: 2.3em;
  width: 25em;
  border: 1px solid #8973ff;
`;

type ModalProps = {
  hasFooter?: boolean;
  onClose?: () => void;
  show?: boolean;
};

const Modal: FC<ModalProps> = ({ children, onClose, show = false, hasFooter = true }) => {
  const closeModal = () => {
    onClose && onClose();
  };

  return (
    <>
      {show &&
        createPortal(
          <>
            <Overlay data-testid={MODAL_OVERLAY} />
            <ModalContainer>
              <ModalHeader>
                <IconContainer>
                  <Warning />
                </IconContainer>
              </ModalHeader>
              <ContentContainer>{children}</ContentContainer>
              {hasFooter && (
                <FooterContainer>
                  <OKButton onClick={closeModal}>OK</OKButton>
                </FooterContainer>
              )}
            </ModalContainer>
          </>,
          document.body,
        )}
    </>
  );
};

export default Modal;
