import React, { FC } from 'react';
import SidebarNav, { SidebarNavProps } from './SidebarNav';
import SidebarShare, { SidebarShareProps } from './SidebarShare';

export type TopSidebarProps =
  | {
      topSidebarType: 'NAV';
      topSidebarProps: SidebarNavProps;
    }
  | {
      topSidebarType: 'SHARE';
      topSidebarProps: SidebarShareProps;
    };

const TopSidebar: FC<TopSidebarProps> = props => {
  const { topSidebarType } = props;

  switch (topSidebarType) {
    case 'NAV':
      return <SidebarNav {...props.topSidebarProps} />;
    case 'SHARE':
      return <SidebarShare {...props.topSidebarProps} />;
  }
};

export default TopSidebar;
