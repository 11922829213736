import { push } from 'connected-react-router';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configuration from 'configuration';
import Routes from 'enums/Routes';
import { isLoggedIn } from 'helpers/limitationHelper';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { selectStudyIds } from 'selectors/viewer/selectStudy';
import { RootState } from 'slices/RootState';
import { studyListActions } from 'slices/studyListSlice';
import { studyActions } from 'slices/studySlice';
import { StudyIds } from 'types/study/Study';

const { LIST, HOME } = Routes;

const isStudyResPresent = (accessionNumber: string, studyInstanceUid: string, studyIds?: StudyIds): boolean =>
  accessionNumber === studyIds?.accessionNumber && studyInstanceUid === studyIds?.studyInstanceUid;

const useViewerScreenRouter = (): void => {
  const dispatch = useDispatch();
  const studyIds = useSelector(selectStudyIds);
  const studiesQuery = useSelector(selectStudiesQuery);
  const { action } = useSelector(({ router }: RootState) => router);
  const { accessionNumber, studyInstanceUid } = queryString.parse(window.location.search);
  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    return () => {
      dispatch(studyActions.runResetStudyEffect());
    };
  }, [dispatch, action]);

  useEffect(() => {
    userLoggedIn && dispatch(studyListActions.fetch(studiesQuery));
  }, [dispatch, userLoggedIn, studiesQuery]);

  useEffect(() => {
    if (typeof accessionNumber === 'string' && typeof studyInstanceUid === 'string') {
      !isStudyResPresent(accessionNumber, studyInstanceUid, studyIds) &&
        dispatch(studyActions.runInitStudyEffect({ accessionNumber, studyInstanceUid }));
    } else {
      dispatch(push(userLoggedIn && configuration.isDemo ? LIST : HOME));
    }
  }, [accessionNumber, studyInstanceUid, dispatch, studyIds, userLoggedIn]);
};

export default useViewerScreenRouter;
