import { ChangeEvent, ClipboardEvent, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

const { setQuery } = studyListActions;

export type UserInput = KeyboardEvent | MouseEvent;

export type UseSearchBarTypes = {
  isKeyboardEvent: (e: UserInput) => boolean;
  isMouseEvent: (e: UserInput) => boolean;
  handleSearch: (e: UserInput) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handlePaste: (e: ClipboardEvent) => void;
  handleClear: () => void;
  value: string;
};

const isKeyboardEvent = (e: UserInput): e is KeyboardEvent => (e as KeyboardEvent).key !== undefined;
const isMouseEvent = (e: UserInput): e is MouseEvent => (e as MouseEvent).button !== undefined;
const isSubmitAction = (e: UserInput) =>
  (isKeyboardEvent(e) && e.key === 'Enter') || (isMouseEvent(e) && e.button === 0);

const useSearchBar = (): UseSearchBarTypes => {
  const dispatch = useDispatch();
  // keep track of value for the actual search request
  const { search = '' } = useSelector(selectStudiesQuery);
  // keep track of value for thesearchbar input
  const [value, setValue] = useState(search);

  // clear the internal state if the search query param is reset
  useEffect(() => {
    !search && search !== value && setValue(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSearch = (e: UserInput) =>
    isSubmitAction(e) && value !== search && dispatch(setQuery({ search: value, page: 1 }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handlePaste = (e: ClipboardEvent) => {
    const target = e.target as HTMLInputElement;
    setValue(target?.value);
  };

  const handleClear = () => {
    setValue('');
    dispatch(setQuery({ search: '', page: 1 }));
  };

  return {
    isKeyboardEvent,
    isMouseEvent,
    handleSearch,
    handleChange,
    handlePaste,
    handleClear,
    value,
  };
};

export default useSearchBar;
