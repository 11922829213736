type KeyValuePairs = {
  [name: string]: unknown;
};

// loop object and remove any items which are null/undefined or empty strings
const retainValidParams = (obj: KeyValuePairs): KeyValuePairs => {
  const result: KeyValuePairs = {};
  Object.entries(obj).forEach(([name, value]) => {
    // we want to retain meaningful values, such as -1, 0, strings
    const newValue = typeof value === 'string' ? value.trim() : value;
    if (newValue !== '' && newValue !== null && newValue !== undefined) result[name] = newValue;
  });
  return result;
};

export default retainValidParams;
