import { combineEpics, Epic, ofType } from 'redux-observable';
import { merge, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getStudies } from 'api/queries';
import formatStudy from 'helpers/formatStudy';
import { ActionTypes } from 'slices/ActionTypes';
import { FetchFailedType, FetchType, SetStudiesType, studyListActions } from 'slices/studyListSlice';

const { fetch: runFetchEffect } = studyListActions;

const fetchEpic: Epic<ActionTypes, FetchFailedType | SetStudiesType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchType>(runFetchEffect.type),
    switchMap(async ({ payload = {} }) => {
      const { studies, totalRecords } = await getStudies(payload);
      const formattedStudies = studies.map(formatStudy);

      return studyListActions.setStudies({ studies: formattedStudies, totalRecords });
    }),
    catchError(error => merge(of(studyListActions.fetchFailed()), throwError(error))),
  );

const studyListEpic = combineEpics(fetchEpic);

export default studyListEpic;
