import Routes from 'enums/Routes';
import About from 'icons/About';
import AboutSolid from 'icons/AboutSolid';
import { AnnaLogo, AnnaLogoSolid } from 'icons/AnnaLogo';
import { Brain, BrainSolid } from 'icons/Brain';
import { Lungs, LungsSolid } from 'icons/Lungs';
import SignOut from 'icons/SignOut';
import SignOutSolid from 'icons/SignOutSolid';
import Studies from 'icons/Studies';
import StudiesSolid from 'icons/StudiesSolid';
import { Swap, SwapSolid } from 'icons/Swap';
import { User, UserSolid } from 'icons/User';
import { authActions } from 'slices/authSlice';
import NavMenuConfig, { NavMenuConfigItem } from 'types/NavItemConfig';
import { LINK_PRIVACY_POLICY } from './links';

const { HOME, LIST, LOGIN, CXR_SAMPLE, CTB_SAMPLE } = Routes;

export const HOME_TEXT = 'Annalise demo home';
export const CXR_SAMPLE_TEXT = 'CXR demo';
export const CTB_SAMPLE_TEXT = 'CTB demo';
export const UPLOADED_TEXT = 'Uploaded studies';
export const ABOUT_TEXT = 'About annalise.ai';
export const BACK_TEXT = 'Switch to public demo';
export const SIGN_IN_TEXT = 'Sign in to private demo';
export const SIGN_OUT_TEXT = 'Sign out of private demo';
export const PRIVACY_POLICY_TEXT = 'Privacy policy';

const checkIsPublicDemo: NavMenuConfigItem['shouldShow'] = ({ pathname, isCurrentlyLoggedIn }) =>
  pathname !== LOGIN && !isCurrentlyLoggedIn;

const NAV_MENU_CONFIG: NavMenuConfig = [
  {
    type: 'ROUTE',
    Icon: Lungs,
    IconHover: LungsSolid,
    route: CXR_SAMPLE,
    text: CXR_SAMPLE_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ROUTE',
    Icon: Brain,
    IconHover: BrainSolid,
    route: CTB_SAMPLE,
    text: CTB_SAMPLE_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ROUTE',
    Icon: Studies,
    IconHover: StudiesSolid,
    route: LIST,
    text: UPLOADED_TEXT,
    shouldShow: ({ isCurrentlyLoggedIn }) => isCurrentlyLoggedIn,
  },
  {
    type: 'DIVIDER',
    shouldShow: ({ pathname }) => pathname !== LOGIN,
  },
  {
    type: 'LINK',
    Icon: About,
    IconHover: AboutSolid,
    href: LINK_PRIVACY_POLICY,
    text: PRIVACY_POLICY_TEXT,
    shouldShow: () => true,
  },
  {
    type: 'LINK',
    Icon: AnnaLogo,
    IconHover: AnnaLogoSolid,
    href: 'https://annalise.ai/',
    text: ABOUT_TEXT,
    shouldShow: () => true,
  },
  {
    type: 'DIVIDER',
    shouldShow: () => true,
  },
  {
    type: 'ROUTE',
    Icon: Swap,
    IconHover: SwapSolid,
    route: HOME,
    text: BACK_TEXT,
    shouldShow: ({ pathname }) => pathname === LOGIN,
  },
  {
    type: 'ROUTE',
    Icon: User,
    IconHover: UserSolid,
    route: LOGIN,
    text: SIGN_IN_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ACTION',
    Icon: SignOut,
    IconHover: SignOutSolid,
    action: authActions.logout,
    payload: { redirect: HOME },
    text: SIGN_OUT_TEXT,
    shouldShow: ({ isCurrentlyLoggedIn }) => isCurrentlyLoggedIn,
  },
];

export default NAV_MENU_CONFIG;
