import styled from 'styled-components';
import Help from 'components/Help';
import SearchBar from 'components/SearchBar';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Logo from './Logo';

const { HEADER_HEIGHT } = APP;
const { HEADER } = ReactTestingLibraryDataProperties;

type HeaderProps = {
  hasSearchBar?: boolean;
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};
const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}rem;
  align-items: center;
  padding: 0 2rem;
  grid-area: nav;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Header = ({ hasSearchBar = false, children }: HeaderProps): JSX.Element => {
  return (
    <Container data-testid={HEADER}>
      <Logo />
      {hasSearchBar && <SearchBar />}
      <ActionsContainer>
        <Help />
        {children}
      </ActionsContainer>
    </Container>
  );
};

export default Header;
