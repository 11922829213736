import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudiesTotalRecords, selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

// Used for the viewer worklist pagination
export type UsePaginationTypes = {
  handleNext: () => void;
  handlePrevious: () => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
};

const usePagination = (): UsePaginationTypes => {
  const dispatch = useDispatch();
  const { setQuery } = studyListActions;
  const totalRecords = useSelector(selectStudiesTotalRecords);

  const { page = 1, limit } = useSelector(selectStudiesQuery);

  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  useEffect(() => {
    if (!totalRecords || !limit) {
      setIsNextDisabled(true);
      setIsPrevDisabled(true);
      return;
    }

    const totalPages = Math.ceil(totalRecords / limit);
    setIsNextDisabled(page >= totalPages);
    setIsPrevDisabled(page <= 1);
  }, [totalRecords, page, limit]);

  const handleNext = () => {
    dispatch(setQuery({ page: page + 1 }));
  };

  const handlePrevious = () => {
    dispatch(setQuery({ page: page - 1 }));
  };

  return { handleNext, handlePrevious, isNextDisabled, isPrevDisabled };
};

export default usePagination;
