import { colors } from '@annaliseai/anna-design-tokens';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ReactComponent as ArrowMove } from '@fluentui/svg-icons/icons/arrow_move_24_regular.svg';
import { ReactComponent as Zoom } from '@fluentui/svg-icons/icons/zoom_in_24_regular.svg';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import loganColors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import ToolNames from 'enums/ToolNames';
import Contrast from 'icons/Contrast';
import Undo from 'icons/Undo';
import { cxrViewerActions, selectActiveControl } from 'slices/cxrViewerSlice';
import { EventActions, EventCategories } from 'types/Matomo';
import CxrImageControlsType from 'types/viewer/cxrViewer/CxrImageControls';
import Button from './Button';

const { IMAGE_CONTROLS, IMAGE_CONTROLS__ZOOM, IMAGE_CONTROLS__PAN, IMAGE_CONTROLS__WINDOW, IMAGE_CONTROLS__RESET } =
  ReactTestingLibraryDataProperties;

const { WHITE_15, GREY_6 } = loganColors;
const { GRAY, WHITE } = colors;
const { WWWC, PAN, ZOOM } = ToolNames;
const { setActiveControl } = cxrViewerActions;
const { STUDY } = EventCategories;
const { HIT } = EventActions;

const Container = styled.div.attrs({
  'data-testid': IMAGE_CONTROLS,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
`;

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlButton = styled(Button)<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2.25rem;
  border: 1px solid transparent;
  border-radius: 0.625rem;
  padding: 0;
  margin: 0.75rem 0;

  &:hover {
    background-color: ${WHITE_15};
  }
  &:focus {
    outline-width: 0;
  }
  &:focus-visible {
    outline-width: initial;
  }
  &:active {
    border-color: ${GRAY};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${WHITE_15};
      border-color: ${GRAY};
      & svg {
        fill: ${WHITE};
      }
    `}
`;

const Separator = styled.span`
  border-top: 1px solid ${GREY_6};
  margin: 0.5rem 0;
  width: 2rem;
`;

const CxrImageControls: FC = () => {
  const dispatch = useDispatch();
  const activeControl = useSelector(selectActiveControl);
  const { trackEvent } = useMatomo();

  const onToolClick = useCallback(
    (imageControl: CxrImageControlsType) => {
      if (activeControl === imageControl) {
        dispatch(setActiveControl(null));
      } else {
        dispatch(setActiveControl(imageControl));
      }
      trackEvent({
        category: STUDY,
        action: HIT,
        name: imageControl,
      });
    },
    [activeControl, dispatch, trackEvent],
  );

  const onResetClick = useCallback(() => {
    dispatch(setActiveControl('reset'));
    trackEvent({
      category: STUDY,
      action: HIT,
      name: 'reset',
    });
  }, [dispatch, trackEvent]);

  return (
    <Container>
      <ToolsContainer>
        <ControlButton
          data-testid={IMAGE_CONTROLS__WINDOW}
          title="Window/Level"
          isActive={activeControl === WWWC}
          onClick={onToolClick.bind(null, WWWC)}
        >
          <Contrast color={GRAY} />
        </ControlButton>
        <ControlButton
          data-testid={IMAGE_CONTROLS__ZOOM}
          title="Zoom"
          isActive={activeControl === ZOOM}
          onClick={onToolClick.bind(null, ZOOM)}
        >
          <Zoom fill={GRAY} />
        </ControlButton>
        <ControlButton
          data-testid={IMAGE_CONTROLS__PAN}
          title="Pan"
          isActive={activeControl === PAN}
          onClick={onToolClick.bind(null, PAN)}
        >
          <ArrowMove fill={GRAY} />
        </ControlButton>
      </ToolsContainer>
      <Separator />
      <ControlButton data-testid={IMAGE_CONTROLS__RESET} title="Reset" onClick={onResetClick}>
        <Undo color={GRAY} />
      </ControlButton>
    </Container>
  );
};

export default CxrImageControls;
