import React, { FC } from 'react';
import styled from 'styled-components';
import PaginationButton from './PaginationButton';
import PaginationIndexes from './PaginationIndexes';

type PaginationProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  previousPage: () => void;
};

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

const Pagination: FC<PaginationProps> = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  previousPage,
}) => (
  <Grid>
    <PaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {'<<'}
    </PaginationButton>
    <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
      {'< prev'}
    </PaginationButton>
    <PaginationIndexes currentPageIndex={pageIndex + 1} gotoPage={gotoPage} pageCount={pageCount} />
    <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
      {'next >'}
    </PaginationButton>
    <PaginationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {'>>'}
    </PaginationButton>
  </Grid>
);

export default Pagination;
