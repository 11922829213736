import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import loganColors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import {
  selectImageViewportScale,
  selectImageViewportWindowCenter,
  selectImageViewportWindowWidth,
} from 'slices/cxrViewerSlice';

const { GREY_3 } = loganColors;
const { DARK_GRAY } = colors;

const { IMAGE_STATUS, IMAGE_STATUS__WINDOW_WIDTH, IMAGE_STATUS__WINDOW_CENTER, IMAGE_CONTROLS__ZOOM } =
  ReactTestingLibraryDataProperties;

const Container = styled.div.attrs({
  'data-testid': IMAGE_STATUS,
})`
  color: ${DARK_GRAY};
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  & > div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  span {
    color: ${GREY_3};
  }
`;

const ImageStatus: FC = () => {
  const scale = useSelector(selectImageViewportScale);
  const windowWidth = useSelector(selectImageViewportWindowWidth);
  const windowCenter = useSelector(selectImageViewportWindowCenter);

  return (
    <Container>
      <div>
        Z: <span data-testid={IMAGE_CONTROLS__ZOOM}>{Math.round(scale * 100)}</span>% | W:{' '}
        <span data-testid={IMAGE_STATUS__WINDOW_WIDTH}>{Math.round(windowWidth)}</span> L:{' '}
        <span data-testid={IMAGE_STATUS__WINDOW_CENTER}>{Math.round(windowCenter)}</span>
      </div>
    </Container>
  );
};

export default ImageStatus;
