import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'components/PrimaryButton';
import useSearchBar from 'components/SearchBar/useSearchBar';
import WorklistPagination from 'components/WorklistPagination/WorklistPagination';
import worklistColumns from 'components/WorklistTable/WorklistColumns';
import {
  Body,
  Cell,
  Head,
  Row,
  RowHeader,
  Table,
  NoResultsContainer,
  NoResultsNotice,
} from 'components/WorklistTable/WorklistTable.styles';
import { AugmentedColumnDef } from 'components/WorklistTable/WorklistTypes';
import FetchStates from 'enums/FetchStates';
import Routes from 'enums/Routes';
import usePagination from 'hooks/usePagination';
import usePolledStudies from 'hooks/usePolledStudies';
import { selectStudiesFetchState, selectStudiesQuery } from 'selectors/studyList/selectStudyList';

const { FULFILLED } = FetchStates;
const { VIEWER } = Routes;

export const NO_RESULTS = 'No results found.';
export const RESET_SEARCH = 'Reset search';

const WorklistTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const { search = '' } = useSelector(selectStudiesQuery);
  const studiesFetchState = useSelector(selectStudiesFetchState);

  const studies = usePolledStudies();
  const { handleClear } = useSearchBar();
  const { handleNext, handlePrevious, isNextDisabled, isPrevDisabled } = usePagination();

  const hasFetchedStudies = studiesFetchState === FULFILLED;
  const hasStudies = !!studies.length;
  const hasSearched = search !== '';

  const onRowClick = (accessionNumber: string, studyInstanceUid: string) => {
    accessionNumber &&
      studyInstanceUid &&
      dispatch(
        push({
          pathname: VIEWER,
          search: `?accessionNumber=${accessionNumber}&studyInstanceUid=${studyInstanceUid}`,
        }),
      );
  };

  const table = useReactTable({
    data: studies,
    columns: worklistColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table>
        <Head>
          <Row>
            {table.getHeaderGroups().map(headerGroup =>
              headerGroup.headers.map(columnHeader => {
                const { meta, header } = columnHeader.column.columnDef as AugmentedColumnDef;
                return (
                  <RowHeader $width={meta?.width || ''} key={columnHeader.id}>
                    {flexRender(header, columnHeader.getContext())}
                  </RowHeader>
                );
              }),
            )}
          </Row>
        </Head>
        <Body>
          {table.getRowModel().rows.map(row => {
            const { accessionNumber, studyInstanceUid, priority } = row.original;
            return (
              <Row key={row.id} onClick={() => onRowClick(accessionNumber, studyInstanceUid)} criticality={priority}>
                {row.getVisibleCells().map(cell => {
                  const cellContext = cell.getContext();
                  const meta = (cellContext.cell.column.columnDef as AugmentedColumnDef).meta;
                  const hasProps = meta?.getProps;

                  return (
                    <Cell {...(hasProps && meta?.getProps(cellContext))} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
        </Body>
      </Table>
      {!hasStudies && (
        <NoResultsContainer>
          {hasFetchedStudies && <NoResultsNotice>{NO_RESULTS}</NoResultsNotice>}
          {hasSearched && <PrimaryButton onClick={handleClear}>{RESET_SEARCH}</PrimaryButton>}
        </NoResultsContainer>
      )}
      {hasStudies && (
        <WorklistPagination
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
        />
      )}
    </>
  );
};

export default WorklistTable;
