import styled from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { PaginationIndexCss } from './sharedCss';

const { PAGINATION_CURRENT_DIV } = ReactTestingLibraryDataProperties;

const PaginationCurrentDiv = styled.div.attrs({
  'data-testid': PAGINATION_CURRENT_DIV,
})`
  ${PaginationIndexCss}
  justify-content: center;
  border-radius: 0.5em;
  background-color: #5436bd;
  color: white;
`;

export default PaginationCurrentDiv;
