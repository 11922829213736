import { ReactComponent as Grid } from '@fluentui/svg-icons/icons/grid_24_regular.svg';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import Share from 'icons/Share';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0.25em 1em;
  border: none;
  margin: 0.25em 1em;
  line-height: 2em;
  height: auto;
  width: 10em;
  color: rgba(255, 255, 255, 0.75);

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.15);
  }

  &:disabled:hover {
    color: rgba(255, 255, 255, 0.15);
    background-color: inherit;
  }

  svg {
    fill: rgba(255, 255, 255, 0.75);
    width: 1.25em;
  }

  span {
    margin-left: 1em;
  }
`;

export type SidebarShareProps = {
  onNewStudyClick: () => void;
  onShareClick: () => void;
};

const SidebarShare: FC<SidebarShareProps> = ({ onShareClick, onNewStudyClick }) => {
  return (
    <>
      <StyledButton onClick={onNewStudyClick}>
        <Grid />
        <span>Studies</span>
      </StyledButton>
      <StyledButton onClick={onShareClick}>
        <Share />
        <span>Share</span>
      </StyledButton>
    </>
  );
};

export default SidebarShare;
