import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import { WholeImage } from 'types/study/Image';
import { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';

const { THUMBNAIL } = ReactTestingLibraryDataProperties;

type ImageContainerProps = {
  url: string;
  isSelected?: boolean;
};

type ThumbnailProps = {
  url: WholeImage['url'];
  viewUuid: CxrFindingView['uuid'];
  isSelected?: boolean;
};

const ImageContainer = styled.div.attrs({
  'data-testid': THUMBNAIL,
  role: 'button',
})<ImageContainerProps>`
  min-height: 3rem;
  min-width: 3rem;
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.5em;
  margin: 0 0.15rem;

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 0.15rem solid var(--yellow);
        `
      : css`
          border: 0.15rem solid var(--black);
        `}
`;

const Thumbnail: FC<ThumbnailProps> = ({ viewUuid, url, isSelected }) => {
  const dispatch = useDispatch();
  const { setActiveView } = cxrViewerActions;

  const selectImage = () => {
    !isSelected && dispatch(setActiveView(viewUuid));
  };

  return <ImageContainer url={url} isSelected={isSelected} onClick={selectImage} />;
};

export default Thumbnail;
