import { format } from 'date-fns';
import DateFormats from 'enums/DateFormats';
import getDateTimeTitle from './getDateTimeTitle';
import getFormattedDateTime, { checkDateTime } from './getFormattedDateTime';

const { DISPLAY_D_MMMM_YYYY, DISPLAY_D_MMM_YYYY } = DateFormats;

export type StudyInformation = {
  studyDate?: string;
  studyDateTime?: string;
  studyTime?: string;
  studyType?: string;
  description?: string;
  sampleDescription?: string;
  accessionNumber?: string;
  createdDateTime?: string;
  createdTime?: string;
  createdDate?: string;
};

export type StudyInformationWithTitles = StudyInformation & {
  studyDateTitle: string;
  studyTimeTitle: string;
  studyDateTimeTitle: string;
  descriptionTitle: string;
  accessionNumberTitle: string;
  createdDateTimeTitle: string;
  studyTypeTitle: string;
};

// The formatStudyInformation helper function formats the study data for display and titles (tooltips).
// Missing data is replaced with a placeholder (if one is provided).
// Titles (for use as tooltips) are returned for each piece of data used in the web viewer.
// Where data is missing, tooltips display a message indicating which data is missing.
// For combined data, such as studyDateTime, if this data is missing then it is generated
// by concatenating existing studyDate and studyTime data or placeholder.

const formatStudyInformation = (
  study: StudyInformation | null,
  placeholder = '',
): Required<
  Pick<
    StudyInformationWithTitles,
    | 'studyDate'
    | 'studyTime'
    | 'description'
    | 'accessionNumber'
    | 'descriptionTitle'
    | 'studyDateTitle'
    | 'studyTimeTitle'
    | 'accessionNumberTitle'
    | 'studyDateTime'
    | 'studyDateTimeTitle'
    | 'createdDateTimeTitle'
    | 'createdDate'
    | 'createdTime'
    | 'studyType'
    | 'studyTypeTitle'
  >
> => {
  const {
    studyDate,
    studyDateTime,
    studyTime,
    createdDateTime,
    createdDate,
    createdTime,
    studyType,
    accessionNumber,
    description,
    sampleDescription,
  } = study || {};

  const parsedStudyDate = studyDate && Date.parse(studyDate) ? new Date(studyDate) : null;
  // Date format DISPLAY_D_MMM_YYYY is preferred for the viewer findings sidebar e.g. 12th Dec YYYY
  const studyDateMMM = parsedStudyDate ? format(new Date(parsedStudyDate), DISPLAY_D_MMM_YYYY) : '';
  // Date format DISPLAY_D_MMMM_YYYY is preferred for titles/tooltips e.g. 12th December YYYY
  const studyDateMMMM = parsedStudyDate ? format(new Date(parsedStudyDate), DISPLAY_D_MMMM_YYYY) : '';

  // For date or time titles/tooltips, the preference is to display complete dateTime (if there is complete data).
  const studyDateTitle =
    studyDateTime ||
    studyDateMMMM ||
    // if studyDateMMMM is undefined but studyDate exists it means the date couldn't be parsed
    // e.g. missing timezone data
    (studyDate && `Study date format may be invalid: ${studyDate}`) ||
    'Study date not available';

  const studyTimeTitle = studyDateTime || studyTime || 'Study time not available';

  // checkDateTime will return a valid studyDateTime, or empty string if there is not enough valid data
  const potentialStudyDateTime = checkDateTime(studyDateTime, studyDateMMM, studyTime);
  const studyDateTimeTitle = getDateTimeTitle(
    potentialStudyDateTime,
    studyDate,
    studyTime,
    studyDateTitle,
    studyTimeTitle,
  );

  // getFormattedDateTime returns what will be displayed the viewer findings sidebar
  const studyDateTimeFormatted = getFormattedDateTime(potentialStudyDateTime, studyDateMMM, studyTime, placeholder);

  // Repeated pattern for study *created* date and time...
  // except don't need to return the combined createdDateTime as this is just used for generating the title/tooltip
  const createdDateTitle = createdDateTime || createdDate || 'Created date not available';
  const createdTimeTitle = createdDateTime || createdTime || 'Created time not available';
  const potentialCreatedDateTime = checkDateTime(createdDateTime, createdDate, createdTime);
  const createdDateTimeTitle = getDateTimeTitle(
    potentialCreatedDateTime,
    createdDate,
    createdTime,
    createdDateTitle,
    createdTimeTitle,
  );

  return {
    studyDate: studyDate || placeholder,
    studyTime: studyTime || placeholder,
    createdDate: createdDate || placeholder,
    createdTime: createdTime || placeholder,
    studyType: studyType || placeholder,
    accessionNumber: accessionNumber || placeholder,
    description: sampleDescription || description || placeholder,
    descriptionTitle: sampleDescription || description || 'Study description not available',
    studyDateTime: studyDateTimeFormatted,
    accessionNumberTitle: accessionNumber || 'Accession number not available',
    studyDateTitle,
    studyTimeTitle,
    studyDateTimeTitle,
    createdDateTimeTitle,
    studyTypeTitle: studyType || 'Study type not available',
  };
};

export default formatStudyInformation;
