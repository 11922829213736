import { useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { InsetIconContainer, SearchContainer, SearchInput } from 'components/SearchBar/SearchBar.styles';
import useSearchBar from 'components/SearchBar/useSearchBar';
import HOTKEYS from 'constants/hotkeys';
import { SearchIcon } from 'icons';

const { SEARCH_HOTKEY } = HOTKEYS;

const SearchBar = (): JSX.Element => {
  const { handleSearch, handleChange, handlePaste, value } = useSearchBar();
  const searchRef = useRef<HTMLInputElement>(null);

  // Ensure search bar is focused on render
  useEffect(() => {
    searchRef?.current?.focus();
  }, []);

  // Use hotkey to jump focus to search bar
  useHotkeys(SEARCH_HOTKEY, () => searchRef?.current?.focus(), [searchRef]);

  return (
    <SearchContainer onSubmit={e => e.preventDefault()}>
      <InsetIconContainer onClick={handleSearch}>
        <SearchIcon />
      </InsetIconContainer>
      <SearchInput
        ref={searchRef}
        placeholder="Search Patient ID, Name, or ACC"
        name="search"
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
        onKeyDown={handleSearch}
      />
    </SearchContainer>
  );
};

export default SearchBar;
