import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import BreadCrumb, { BreadCrumbProps } from 'components/viewer/BreadCrumb';

const StyledButton = styled(Button)`
  padding: 0.25em 0;
  border: none;
  margin: 0;
  width: 2.5em;
  line-height: 2em;
  height: auto;
  color: rgba(255, 255, 255, 0.75);

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.15);
  }

  &:disabled:hover {
    color: rgba(255, 255, 255, 0.15);
    background-color: inherit;
  }
`;

const SidebarNavContainer = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75em;
  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CounterContainer = styled.div`
  margin: 0 0.25em;
`;

export type SidebarNavProps = {
  page: number;
  totalPages: number;
  onPageChange: (nextPage: number) => void;
  breadCrumbProps: BreadCrumbProps;
};

const SidebarNav: FC<SidebarNavProps> = ({ page, totalPages, onPageChange, breadCrumbProps }) => {
  return (
    <SidebarNavContainer isVisible={totalPages !== 0}>
      <BreadCrumb {...breadCrumbProps} />
      <PaginationContainer>
        <StyledButton disabled={page <= 0} onClick={() => onPageChange(page - 1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledButton>
        <CounterContainer>
          {page + 1}/{totalPages}
        </CounterContainer>
        <StyledButton disabled={page >= totalPages - 1} onClick={() => onPageChange(page + 1)}>
          <FontAwesomeIcon icon={faArrowRight} />
        </StyledButton>
      </PaginationContainer>
    </SidebarNavContainer>
  );
};

export default SidebarNav;
