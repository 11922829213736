import JPEG2000Encoder from '@annaliseai/jpeg-2000-encoder';
import { bytesToBase64 } from 'byte-base64';
import log from 'loglevel';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import { CornerstoneWADOImage } from 'types/cornerstone/CornerstoneImage';
import { getBitsAllocated } from './cornerstoneImageHelper';

const { FAILED_TO_READ_IMAGE_FILE } = CustomErrors;

const encodeToJp2000Base64 = async (image: CornerstoneWADOImage): Promise<string> => {
  try {
    const pixelData = image.getPixelData();

    if (!pixelData) {
      throw new CustomError(FAILED_TO_READ_IMAGE_FILE);
    }

    const { width, height } = image;
    const bitsAllocated = getBitsAllocated(image);
    const { buffer: pixelDataBuffer } = pixelData;

    const encodedPixelDataBuffer = await JPEG2000Encoder.encode(pixelDataBuffer, {
      bitsAllocated,
      width,
      height,
    });

    return bytesToBase64(new Uint8Array(encodedPixelDataBuffer));
  } catch (error) {
    log.error(FAILED_TO_READ_IMAGE_FILE, error);
    throw new CustomError(FAILED_TO_READ_IMAGE_FILE);
  }
};

export default encodeToJp2000Base64;
