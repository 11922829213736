import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import configuration from 'configuration';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import { ActionTypes } from 'slices/ActionTypes';
import { ctbViewerActions } from 'slices/ctbViewerSlice';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import { RunInitStudyEffectType, studyActions } from 'slices/studySlice';
import { isCtbStudyExternal, isCxrStudyExternal } from 'typeguards/studyExternal';
import loadStudyExternal from './helpers/loadStudyExternal';

const { UNEXPECTED_FRONTEND_ERROR } = CustomErrors;

const { runInitStudyEffect } = studyActions;

const initStudyEpic: Epic<ActionTypes> = action$ =>
  action$.pipe(
    ofType<ActionTypes, RunInitStudyEffectType>(runInitStudyEffect.type),
    switchMap(async ({ payload: ids }) => {
      const { accessionNumber, studyInstanceUid } = ids;
      return {
        studyExternal: await loadStudyExternal(accessionNumber, studyInstanceUid),
        ids,
      };
    }),
    mergeMap(({ studyExternal, ids }) => {
      const { isViewer } = configuration;
      let patientInformation = {};
      const { study } = studyExternal;
      const {
        type,
        studyDate,
        studyTime,
        description,
        patientAge,
        patientBirthDate,
        patientId,
        patientName,
        patientSex,
      } = study;

      let initModalityAction: ReturnType<
        typeof cxrViewerActions.runInitCxrStudyEffect | typeof ctbViewerActions.runInitCtbStudyEffect
      >;

      if (isViewer) {
        patientInformation = {
          patientAge,
          patientBirthDate,
          patientId,
          patientName,
          patientSex,
        };
      }

      const studyObj = {
        type,
        ids,
        studyDate,
        studyTime,
        description,
      };

      if (isCxrStudyExternal(studyExternal)) {
        initModalityAction = cxrViewerActions.runInitCxrStudyEffect(studyExternal);
      } else if (isCtbStudyExternal(studyExternal)) {
        initModalityAction = ctbViewerActions.runInitCtbStudyEffect(studyExternal);
      } else {
        throw new CustomError(UNEXPECTED_FRONTEND_ERROR);
      }

      return of(
        studyActions.setStudy(studyObj),
        studyActions.setPatientInformation(patientInformation),
        initModalityAction,
      );
    }),
  );

export default initStudyEpic;
