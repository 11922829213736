import axios, { AxiosRequestConfig } from 'axios';
import log from 'loglevel';
import queryString from 'query-string';

/**
 * Cancels requests that are missing accessionNumber and should have one
 * This happens often when switching between studies fast where an API call is in flight
 * and the user has exited a study and entered another one before the API call has finished
 *
 * This is a patch fix of a bigger underlining issue where we are accessing accessionNumber via URL
 * The issue goes like this:
 * 1. User enters a study with accessionNumber=12345
 * 2. We make the first of many sequential API calls to get the study and grab accessionNumber from the URL
 * 3. User exits the study
 * 4. The first API call resolves and starts the second
 * 5. The second API call tries to grab accessionNumber from the URL, but it's no longer there
 *
 * The main culprit for the issue is addSignHeaders.ts where we are accessing accessionNumber from the URL
 *
 * @param requestConfig
 */
const cancelInvalidRequests = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  let { accessionNumber } = queryString.parse(window.location.search);
  if (!accessionNumber && requestConfig?.data?.accessionNumber) {
    accessionNumber = requestConfig.data.accessionNumber;
  }

  // if we can resolve accessionNumber from either URL or request body then we don't cancel the request
  if (accessionNumber) {
    return requestConfig;
  }

  // don't cancel requests if we're uploading images for a study
  if (window.location.pathname === '/progress') {
    return requestConfig;
  }

  // cancel api calls for certain api request URL where accessionNumber is required
  const { url = '' } = requestConfig;
  const apiCallsUrlsToCancel = [
    '/v1/series/.*/predictions/latest',
    '/v1/studies/.*/predictions/latest',
    '/v1/predictions/.*/segments/status',
    '/v1/series/.*/versions/.*',
    '/v1/predictions/.*/slices',
  ];
  if (!accessionNumber) {
    if (apiCallsUrlsToCancel.some(apiCallUrlRegex => new RegExp(apiCallUrlRegex).test(url))) {
      log.debug('axios api call cancelled because it was missing accessionNumber', { url, requestConfig });
      throw new axios.Cancel();
    }
  }

  return requestConfig;
};

export default cancelInvalidRequests;
