import { StudyOverview } from '@annaliseai/api-specifications';
import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';
import { RETAINING_WINDOW } from 'helpers/limitationHelper';

const { UPLOADED_STUDIES } = CookieKeys;

export const storeUploadedStudies = (uploadedStudies: StudyOverview[]): void => {
  Cookies.set(UPLOADED_STUDIES, uploadedStudies, { expires: RETAINING_WINDOW });
};

export const retrieveUploadedStudies = (): StudyOverview[] => Cookies.getJSON(UPLOADED_STUDIES) || [];
