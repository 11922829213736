import styled from 'styled-components';

type ThumbnailBadgeProps = {
  backgroundColor?: string;
  color?: string;
};

const ThumbnailBadge = styled.span<ThumbnailBadgeProps>`
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor = 'var(--black)' }) => backgroundColor};
  color: ${({ color = 'var(--white)' }) => color};
  text-transform: uppercase;
  font-weight: 900;
  border-radius: 0.5em;
  border: 0.125rem solid ${({ color = 'var(--white)' }) => color};
  padding: 0.25rem 0.5rem;
  opacity: 0.4;
`;

export default ThumbnailBadge;
