import { RootState } from 'slices/RootState';
import { StudyListState } from 'slices/studyListSlice';

export const selectStudies = ({ studyList }: RootState): StudyListState['studies'] => studyList?.studies;

export const selectStudiesFetchState = ({ studyList }: RootState): StudyListState['fetchState'] =>
  studyList?.fetchState;

export const selectStudiesQuery = ({ studyList }: RootState): StudyListState['query'] => studyList?.query;

export const selectStudiesTotalRecords = ({ studyList }: RootState): StudyListState['totalRecords'] =>
  studyList?.totalRecords;
