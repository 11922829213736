import { colors } from '@annaliseai/anna-design-tokens';
import Icon from 'types/Icon';

const { GRAY_3 } = colors;

const Search = ({ color = GRAY_3 }: Icon): JSX.Element => {
  return (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 20 20">
      <path d="M8.5 3a5.5 5.5 0 0 1 4.383 8.823l4.147 4.147a.75.75 0 0 1-.976 1.133l-.084-.073l-4.147-4.147A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8a4 4 0 0 0 0-8Z" />
    </svg>
  );
};

export default Search;
