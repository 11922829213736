import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import { CriticalityConfig } from 'components/WorklistTable/WorklistTypes';
import colors from 'constants/colors';
import Alert from 'icons/Alert';
import { StudyStatus } from 'types/DisplayStudyInformation';

const { RED_1, YELLOW_1, AQUA_1, GREY_4 } = colors;
const { BLACK, WHITE, DARK_3 } = annaColors;

export const criticalityConfig: CriticalityConfig = {
  CRITICAL: {
    color: RED_1,
    message: 'Critical',
    icon: <Alert color={BLACK} size={1.2} />,
  },
  PRIORITY: {
    color: YELLOW_1,
    message: 'Priority',
    icon: <Alert color={BLACK} size={1.2} />,
  },
  NON_URGENT: {
    color: AQUA_1,
    message: 'Non-urgent',
  },
};

export const Table = styled.table`
  margin: 0.5rem 0;
  font-size: 0.75rem;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 1px;
  border-radius: 8px;

  th {
    padding: 0.8em;
    background-color: transparent;
  }
`;

export const Head = styled.thead``;

export const Body = styled.tbody`
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

export const Row = styled.tr<{ criticality?: StudyStatus | undefined }>`
  color: ${WHITE};
  background-color: ${DARK_3};
  th {
    background-color: ${BLACK};
  }

  :hover {
    ${({ onClick }) => onClick && `cursor: pointer;`}
  }

  ${({ criticality }) =>
    criticality &&
    criticalityConfig[criticality] &&
    `
      color: ${BLACK};
      font-weight: 700;
      background-color: ${criticalityConfig[criticality].color}`}
`;

export const RowHeader = styled.th<{ $width?: string }>`
  font-weight: 700;
  letter-spacing: 0.08em;
  width: ${({ $width }) => $width};

  :hover {
    cursor: default;
  }
`;

export const Cell = styled.td`
  position: relative;
  padding: 1rem 0.5rem;
  word-break: break-word;
  white-space: unset;

  // This is the divider between cells
  :not(:last-child) {
    :after {
      content: '';
      height: 60%;
      position: absolute;
      right: 0;
      top: 20%;
      border-right: 1px solid ${GREY_4};
    }
  }

  :first-child {
    padding-left: 1rem;
  }

  :last-child {
    padding-right: 1rem;
  }
`;

export const PatientCellInterior = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 50% 0.5fr 0.5fr;
  gap: 0;
`;

export const DateCellInterior = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 30% 70%;
  gap: 0;
`;

export const CellInterior = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const CellContent = styled.div`
  :first-child {
    text-align: left;
  }
  :last-child {
    text-align: right;
  }
`;

export const NoResultsContainer = styled.div`
  margin: 2rem 1rem;
  text-align: center;
  width: 100%;

  & > button {
    display: block;
    margin: 0 auto;
    max-height: 40px;
    padding: 0.5rem 1.5rem 0.5rem;
  }
`;

export const NoResultsNotice = styled.div`
  margin-bottom: 1.5rem;
`;
