import ConfidenceIndicator, { ConfidenceIndicatorProps } from '@annaliseai/confidence-indicator';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import Finding from 'types/study/Finding';

const { CONFIDENCE_INDICATOR } = ReactTestingLibraryDataProperties;
const { CONFIDENCE_INDICATOR_HEIGHT, CONFIDENCE_INDICATOR_MARGIN_BOTTOM } = APP;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${CONFIDENCE_INDICATOR_HEIGHT}rem;
  min-height: 4rem;
  margin: 0 0.5rem ${CONFIDENCE_INDICATOR_MARGIN_BOTTOM}rem 0.5rem;
  width: 100%;
`;

const ConfidenceBar: FC = () => {
  const activeFinding = useSelector(selectActiveFinding);

  if (!activeFinding || (activeFinding && activeFinding.isParent)) {
    return <IndicatorContainer />;
  }

  const { confidence, predictionProbability } = activeFinding as Finding;

  const indicatorProps: ConfidenceIndicatorProps = {
    isFullSize: true,
    isScaledDown: false,
    leftLabel: 'ABSENT',
    rightLabel: 'PRESENT',
    testId: CONFIDENCE_INDICATOR,
    scaledError: confidence,
    scaledScore: predictionProbability,
  };
  return (
    <IndicatorContainer>
      <ConfidenceIndicator {...indicatorProps} />
    </IndicatorContainer>
  );
};

export default ConfidenceBar;
