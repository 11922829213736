import format from 'date-fns/format';

const formatDateTime = (dateTime: string | Date, formatter: string, fallback = ''): string => {
  let formattedDateTime = fallback;

  if (dateTime instanceof Date) {
    formattedDateTime = format(dateTime, formatter);
  } else if (Date.parse(dateTime)) {
    formattedDateTime = format(new Date(dateTime), formatter);
  }

  return formattedDateTime;
};

export default formatDateTime;
