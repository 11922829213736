import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

type LogoDemoProps = {
  className?: string;
};

const { LOGO_DEMO } = ReactTestingLibraryDataProperties;

const LogoDemo: FC<LogoDemoProps> = ({ className }) => (
  <svg
    className={className}
    data-testid={LOGO_DEMO}
    width="81"
    height="40"
    viewBox="0 0 81 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M14.686 21.935L8.79 11.727c-.08-.143-.239-.239-.414-.239s-.335.096-.414.24L2.064 21.934c-.088.152-.088.335 0 .478.087.144.247.24.414.24 0 0 3.172 0 3.172-.008.701-.032 1.45-.295 1.976-1.267l.749-1.276.741 1.284c.55 1.02 1.355 1.259 2.08 1.267h3.068c.175 0 .335-.096.414-.24.08-.143.096-.326.008-.478zm-3.41-.239c-.534 0-.965-.127-1.307-.765L8.797 18.9c-.087-.151-.247-.239-.422-.239s-.335.096-.414.24L6.78 20.93c-.342.638-.773.765-1.307.765h-2.16l5.061-8.765 5.06 8.765h-2.16z"
        fill="#fff"
      />
    </g>
    <path
      d="M28.329 28.5c2.42 0 3.236-1.504 3.661-2.19h.23v1.428c0 .277.223.5.5.5h.863c.276 0 .5-.223.5-.5V12c0-.276-.224-.5-.5-.5h-.93c-.275 0-.5.224-.5.5v5.679h-.163c-.425-.654-1.176-2.158-3.628-2.158-3.172 0-5.362 2.517-5.362 6.473 0 3.989 2.19 6.506 5.329 6.506zm.261-1.733c-2.419 0-3.661-2.125-3.661-4.805 0-2.648 1.21-4.708 3.661-4.708 2.354 0 3.597 1.896 3.597 4.707 0 2.845-1.275 4.806-3.597 4.806zM42.686 28.5c2.328 0 4.084-1.063 4.82-2.69.116-.257-.041-.544-.312-.62l-.927-.26c-.25-.07-.508.066-.633.294-.56 1.029-1.606 1.543-2.948 1.543-2.264 0-3.825-1.463-3.915-4.152h8.613c.276 0 .5-.223.5-.5v-.317c0-4.675-2.778-6.277-5.394-6.277-3.4 0-5.656 2.68-5.656 6.539 0 3.857 2.223 6.44 5.852 6.44zm-3.915-7.552c.131-1.953 1.512-3.694 3.72-3.694 2.092 0 3.432 1.57 3.432 3.694H38.77zM50.358 27.738c0 .277.224.5.5.5h.93c.275 0 .5-.223.5-.5v-7.346c0-1.839 1.34-3.138 2.843-3.138 1.463 0 2.485.956 2.485 2.386v8.098c0 .277.224.5.5.5h.962c.276 0 .5-.223.5-.5v-7.673c0-1.618 1.013-2.811 2.778-2.811 1.373 0 2.55.727 2.55 2.582v7.902c0 .277.224.5.5.5h.93c.275 0 .5-.223.5-.5v-7.901c0-2.95-1.586-4.316-3.826-4.316-1.798 0-3.114.826-3.76 2.125h-.13c-.621-1.34-1.717-2.125-3.367-2.125-1.635 0-2.845.785-3.368 2.125h-.163v-1.461c0-.276-.224-.5-.5-.5h-.864c-.276 0-.5.223-.5.5v11.553zM75.001 28.5c3.4 0 5.689-2.583 5.689-6.473 0-3.923-2.288-6.506-5.689-6.506-3.4 0-5.688 2.583-5.688 6.506 0 3.89 2.288 6.473 5.688 6.473zm0-1.733c-2.582 0-3.76-2.223-3.76-4.74 0-2.517 1.178-4.773 3.76-4.773 2.583 0 3.76 2.256 3.76 4.773s-1.177 4.74-3.76 4.74z"
      fill="gray"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(2 10.7)" d="M0 0h12.75v12.75H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoDemo;
