import { createColumnHelper } from '@tanstack/react-table';
import {
  CellContent,
  CellInterior,
  criticalityConfig,
  DateCellInterior,
  PatientCellInterior,
} from 'components/WorklistTable/WorklistTable.styles';
import formatPatientAgeSex from 'helpers/formatPatientAgeSex';
import maybeTruncate from 'helpers/maybeTruncate';
import DisplayStudyInformation, { StudyStatus } from 'types/DisplayStudyInformation';

const columnHelper = createColumnHelper<DisplayStudyInformation>();
const worklistColumns = [
  columnHelper.accessor(row => row.createdDateTime, {
    header: 'Created Date',
    id: 'createdDate',
    meta: {
      width: '11rem',
    },
    cell: row => {
      const data = row.row.original;
      const { createdTime, createdDate } = data;
      return (
        <DateCellInterior>
          <CellContent>{createdTime}</CellContent>
          <CellContent>{createdDate}</CellContent>
        </DateCellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.studyDateTime, {
    header: 'Study Date',
    id: 'studyDate',
    meta: {
      width: '11rem',
    },
    cell: row => {
      const data = row.row.original;
      const { studyTime, studyDate } = data;
      return (
        <DateCellInterior>
          <CellContent>{studyTime || '-'}</CellContent>
          <CellContent>{studyDate || '-'}</CellContent>
        </DateCellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientId, {
    header: 'Patient ID',
    id: 'patientId',
    cell: row => {
      const { patientId } = row.row.original;
      return (
        <CellInterior>
          <CellContent>{(patientId && maybeTruncate(patientId, 10)) || '-'}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.patientName, {
    header: 'Patient',
    id: 'patient',
    cell: row => {
      const data = row.row.original;
      const { patientName, patientAge, patientSex, patientBirthDate } = data;
      return (
        <PatientCellInterior>
          <CellContent>{(patientName && maybeTruncate(patientName, 25)) || '-'}</CellContent>
          <CellContent>{formatPatientAgeSex(patientAge, patientSex, '-')}</CellContent>
          <CellContent>{patientBirthDate || '-'}</CellContent>
        </PatientCellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.studyType, {
    header: 'Modality',
    id: 'studyType',
  }),
  columnHelper.accessor(row => row.accessionNumber, {
    header: 'ACC',
    id: 'accessionNumber',
    cell: row => {
      const { accessionNumber } = row.row.original;
      return (
        <CellInterior>
          <CellContent>{maybeTruncate(accessionNumber, 5) || '-'}</CellContent>
        </CellInterior>
      );
    },
  }),
  columnHelper.accessor(row => row.priority, {
    header: 'AI Results',
    id: 'priority',
    cell: row => {
      const data = row.row.original;
      const { priority } = data;
      return (
        <CellInterior>
          <CellContent>{(priority && criticalityConfig[priority as StudyStatus]?.message) || '-'}</CellContent>
          <CellContent>{(priority && criticalityConfig[priority as StudyStatus]?.icon) || ''}</CellContent>
        </CellInterior>
      );
    },
  }),
];

export default worklistColumns;
