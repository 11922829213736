/* eslint-disable react/jsx-key */
// Next line is disabled as the keu is part of getXXXGroupProps()
import { matchSorter } from 'match-sorter';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Row, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { SearchBox, SearchHeader, SortingHeader, Table, TableCell, TableHeader } from './Table';
import Pagination from './Table/Pagination';

type DataTableColumn = {
  Header: string;
  accessor: string;
  id: string;
  width?: string | number;
};

type DataTableProps = {
  columns: DataTableColumn[];
  data: Record<string, unknown>[];
  isSearchable?: boolean;
  isSortable?: boolean;
};

const PaginationContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  max-width: 72em;
  margin: 2em auto;
  display: flex;
`;

const fuzzyTextFilterFunction = (rows: Row[], id: string, filterValue: string) =>
  matchSorter(rows, filterValue, { keys: [row => row.values[id]] });

// Let the table remove the filter if the string is empty
fuzzyTextFilterFunction.autoRemove = (val: unknown) => !val;

const DataTable: FC<DataTableProps> = ({ columns, data, isSearchable = false, isSortable = false }) => {
  // columns and data needs to me memoized
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFunction,
    }),
    [],
  );

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    preGlobalFilteredRows,
    prepareRow,
    previousPage,
    setGlobalFilter,
    state: { globalFilter, pageIndex },
    visibleColumns,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      filterTypes,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <>
      <Container>
        <Table {...getTableProps()}>
          <thead>
            {isSearchable && (
              <tr>
                <SearchHeader colSpan={visibleColumns.length}>
                  <SearchBox
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </SearchHeader>
              </tr>
            )}
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const sortToggleProps = isSortable ? column.getSortByToggleProps() : undefined;
                  return (
                    <TableHeader align="left" width={column.width} {...column.getHeaderProps(sortToggleProps)}>
                      {column.render('Header')}
                      {isSortable && <SortingHeader isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />}
                    </TableHeader>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <TableCell title={cell.value} align="left" skipEllipsis={!cell.value} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <PaginationContainer>
        {(canNextPage || canPreviousPage) && (
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            previousPage={previousPage}
          />
        )}
      </PaginationContainer>
    </>
  );
};

export default DataTable;
