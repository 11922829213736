// For the record I wanted to call this file Cookeys!

enum CookieKeys {
  AGREE_TO_TERMS_CHECK_BOX = 'acceptedTermsAndConditions',
  AI_GDPR_5 = 'ai_gdpr_5',
  ANALYTICS_USER_ID = 'analyticsUserId',
  CLIENT_ID = 'clientId',
  CLIENT_SECRET = 'clientSecret',
  ONBOARDING_DONE = 'onboardingDone',
  UPLOADED_STUDIES = 'uploadedStudies',
  USER_INFORMATION_SUBMITTED = 'userInformationSubmitted',
}

export default CookieKeys;
