import {
  GetCtbImageSlicesResSchema,
  GetCtbPredictionSegmentSlicesResSchema,
  GetCtbPredictionSegmentsStatusResSchema,
  GetLatestCtbPredictionResSchema,
  GetLatestCxrPredictionResSchema,
  GetSelfResSchema,
  HttpSuccessResponseSchema,
  Joi,
  PostFilterStudiesV2ResSchema,
  PostImageStatusResSchema,
  PostSegmentsStatusResSchema,
} from '@annaliseai/api-specifications';
import { generatePath, matchPath } from 'react-router';

export class Endpoint {
  private readonly pathPattern: string;
  private readonly resSchema: Joi.Schema;

  constructor(pathPattern: string, resSchema: Joi.Schema) {
    this.pathPattern = pathPattern;
    this.resSchema = resSchema;
  }

  getPath(params?: Parameters<typeof generatePath>[1]): string {
    return generatePath(this.pathPattern, params);
  }

  testMatchPath(url: string): boolean {
    return !!matchPath(url, { path: this.pathPattern, exact: true });
  }

  getResSchema(): Joi.Schema {
    return this.resSchema;
  }
}

const endpoints = {
  v1: {
    self: new Endpoint('/v1/self', GetSelfResSchema),
    // TODO - revert back to GetOrgStudiesResSchema once api-specification is updated allow null `patientId`
    studies: new Endpoint('/v1/studies', Joi.object()),
    // TODO - revert back to GetStudiesSearchResSchema once api-specification is updated
    studiesSearch: new Endpoint('/v1/studies/search', Joi.object()),
    studiesPredict: new Endpoint('/v1/studies/predict', HttpSuccessResponseSchema),
    imagesStatus: new Endpoint('/v1/images/status', PostImageStatusResSchema),
    segmentsStatus: new Endpoint('/v1/segments/status', PostSegmentsStatusResSchema),
    imagesUpload: new Endpoint('/v1/images/upload', HttpSuccessResponseSchema),
    studyPrediction: new Endpoint('/v1/studies/:studyInstanceUid/predictions/latest', GetLatestCxrPredictionResSchema),
    ctbSegmentsStatus: new Endpoint(
      '/v1/predictions/:predictionId/segments/status',
      GetCtbPredictionSegmentsStatusResSchema,
    ),
    seriesBaseImages: new Endpoint(
      '/v1/series/:seriesInstanceUid/versions/:seriesVersionId/slices',
      GetCtbImageSlicesResSchema,
    ),
    ctbSegments: new Endpoint(
      '/v1/predictions/:predictionId/segments/:segmentId/slices',
      GetCtbPredictionSegmentSlicesResSchema,
    ),
    seriesPrediction: new Endpoint('/v1/series/:seriesInstanceUid/predictions/latest', GetLatestCtbPredictionResSchema),
  },
  v2: {
    studiesFilter: new Endpoint('/v2/studies/filter', PostFilterStudiesV2ResSchema),
  },
};

export default endpoints;
