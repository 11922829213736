import { StudyType } from '@annaliseai/api-specifications';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import formatStudyInformation, { StudyInformation, StudyInformationWithTitles } from 'helpers/formatDisplayInformation';
import formatPatientInformation, { PatientInfoWithTitles } from 'helpers/formatPatientInformation';
import getSample from 'helpers/getSample';
import mapModality from 'helpers/mapModality';
import selectCtbViewer from 'selectors/viewer/ctb/selectCtbViewer';
import {
  selectStudyIds,
  selectStudyInformation,
  selectStudyPatient,
  selectStudyType,
} from 'selectors/viewer/selectStudy';
import { PatientInformation } from 'types/DisplayStudyInformation';

const { CXR, CTB } = StudyType;

const useDisplayInformation = (): Required<PatientInformation> &
  Required<Omit<StudyInformation, 'sampleDescription' | 'createdDateTime'>> & {
    studyIdentifiers: string;
  } & PatientInfoWithTitles &
  StudyInformationWithTitles => {
  const studyType = useSelector(selectStudyType);
  const patient = useSelector(selectStudyPatient);
  const studyInfo = useSelector(selectStudyInformation);
  const { seriesInfo } = useSelector(selectCtbViewer) || {};
  const { accessionNumber } = useSelector(selectStudyIds) || {};

  const formattedAccessionNumber = mapModality(studyType, { [CXR]: accessionNumber, [CTB]: accessionNumber });
  const sampleDescription = formattedAccessionNumber && getSample(formattedAccessionNumber)?.description;

  const studyIdentifiers =
    mapModality(studyType, {
      [CXR]: accessionNumber && `ACC: ${accessionNumber}`,
      [CTB]: [seriesInfo?.number && `Series ${seriesInfo.number}`, accessionNumber && `ACC: ${accessionNumber}`]
        .filter(Boolean)
        .join(' | '),
    }) || '';

  return useMemo(() => {
    // Description for sample cases is hardcoded - so include it to be formatted if present
    const studyInformation = {
      ...studyInfo,
      accessionNumber,
      sampleDescription,
    };
    return {
      ...formatPatientInformation(patient, '-'),
      ...formatStudyInformation(studyInformation, '-'),
      studyIdentifiers,
    };
  }, [patient, sampleDescription, studyIdentifiers, studyInfo, accessionNumber]);
};

export default useDisplayInformation;
