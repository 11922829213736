import { useMatomo } from '@datapunt/matomo-tracker-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import matomoRouteMappings from 'constants/matomoRouteMapping';
import { SAMPLE, UPLOAD } from 'constants/sampleOrUpload';
import Routes from 'enums/Routes';
import { retrieveOnboardingDone } from 'helpers/cookies/onBoardingDoneHelper';
import { retrieveUserInformationSubmitted } from 'helpers/cookies/userInformationHelper';
import isSample from 'helpers/isSample';
import { isLoggedIn } from 'helpers/limitationHelper';
import useQuery from 'helpers/router/useQuery';
import { EventCategories, EventActions } from 'types/Matomo';

const { WEBSITE_NAVIGATION, WEBSITE_NAVIGATION_FAILED } = EventCategories;
const { VISITED } = EventActions;

enum BrowsingStatus {
  NEW = 'new',
  RETURNING = 'returning',
}
const { NEW, RETURNING } = BrowsingStatus;

type GetMatomoNameParams = {
  accessionNumber: string | null;
  checkOrigin: boolean;
  checkCookies: boolean;
  isFirstTimeOnFindings: boolean;
  isReturningUser: boolean;
  name: string;
};

const getMatomoName = ({
  accessionNumber,
  checkOrigin,
  checkCookies,
  isFirstTimeOnFindings,
  isReturningUser,
  name,
}: GetMatomoNameParams): string => {
  const origin = accessionNumber && isSample(accessionNumber) ? SAMPLE : UPLOAD;
  const browsingStatus = isReturningUser ? RETURNING : NEW;
  if (name === 'findings') {
    return `${name}_${isFirstTimeOnFindings ? NEW : RETURNING}${checkOrigin ? `_${origin}` : ''}`;
  } else return `${name}${checkCookies ? `_${browsingStatus}` : ''}${checkOrigin ? `_${origin}` : ''}`;
};

const useNavigationTracker = (): void => {
  const { trackEvent, trackPageView } = useMatomo();
  const { pathname } = useLocation();
  const accessionNumber = useQuery().get('accessionNumber');
  const isReturningUser = retrieveUserInformationSubmitted();
  const isFirstTimeOnFindings = !retrieveOnboardingDone();

  const mappingPathname = pathname as Routes;
  const shouldTrack = !isLoggedIn();

  React.useEffect(() => {
    if (shouldTrack) {
      try {
        trackPageView({});
        const { checkCookies, checkOrigin, name } = matomoRouteMappings[mappingPathname];
        trackEvent({
          category: WEBSITE_NAVIGATION,
          action: VISITED,
          name: getMatomoName({
            accessionNumber,
            checkOrigin,
            checkCookies,
            isFirstTimeOnFindings,
            isReturningUser,
            name,
          }),
        });
      } catch (error) {
        trackEvent({
          category: WEBSITE_NAVIGATION_FAILED,
          action: VISITED,
          name: pathname,
        });
      }
    }
  }, [
    accessionNumber,
    isFirstTimeOnFindings,
    isReturningUser,
    mappingPathname,
    pathname,
    shouldTrack,
    trackEvent,
    trackPageView,
  ]);
};

export default useNavigationTracker;
