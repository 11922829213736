import { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import studyEndpoints from 'constants/studyEndpoints';
import SigningHeaders from 'enums/SigningHeaders';
import { retrieveCredential } from 'helpers/cookies/credentialHelper';
import isSample from 'helpers/isSample';

const {
  X_ANNALISE_AI_CLIENT_ID,
  X_ANNALISE_AI_CLIENT_SECRET,
  X_ANNALISE_AI_FORWARD_PUBLIC,
  X_ANNALISE_AI_FORWARD_SAMPLE,
} = SigningHeaders;

const addSigningHeaders = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const { REACT_APP_FORWARD_PUBLIC_ID, REACT_APP_FORWARD_SAMPLE_ID } = process.env;
  const { clientId, clientSecret } = retrieveCredential();
  const { headers, url = '' } = requestConfig;
  const { accessionNumber } = queryString.parse(window.location.search);
  const isStudyUrl = studyEndpoints.some(studyEndpoint => studyEndpoint.testMatchPath(url));

  let signingHeaders: Record<string, string | undefined>;

  if (clientId && clientSecret) {
    signingHeaders = {
      [X_ANNALISE_AI_CLIENT_ID]: clientId,
      [X_ANNALISE_AI_CLIENT_SECRET]: clientSecret,
    };
  } else if (isStudyUrl && typeof accessionNumber === 'string' && isSample(accessionNumber)) {
    signingHeaders = {
      [X_ANNALISE_AI_FORWARD_SAMPLE]: REACT_APP_FORWARD_SAMPLE_ID,
    };
  } else {
    signingHeaders = {
      [X_ANNALISE_AI_FORWARD_PUBLIC]: REACT_APP_FORWARD_PUBLIC_ID,
    };
  }

  requestConfig.headers = {
    ...headers,
    ...signingHeaders,
  };

  return requestConfig;
};

export default addSigningHeaders;
