import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Disclaimer } from 'components';
import ConfidenceBar from 'components/ConfidenceBar';
import CxrImageControls from 'components/CxrImageControls';
import Header from 'components/Header';
import LocalisationToggle from 'components/LocalisationToggle';
import OnboardingSidebar from 'components/OnboardingSidebar';
import Sidebar from 'components/sidebar/Sidebar';
import configuration from 'configuration';
import loganColors from 'constants/colors';
import { APP } from 'constants/sizes';
import viewerConfig from 'constants/viewerConfig';
import Routes from 'enums/Routes';
import { retrieveOnboardingDone, storeOnboardingDone } from 'helpers/cookies/onBoardingDoneHelper';
import { isLoggedIn } from 'helpers/limitationHelper';
import useDisplayInformation from 'hooks/useDisplayInformation';
import useSidebarFindings from 'hooks/useSidebarFindings';
import useSidebarNav from 'hooks/useSidebarNav';
import useStudiesPagination from 'hooks/useStudiesPagination';
import useViewerScreenRouter from 'hooks/useViewerScreenRouter';
import { selectStudyType } from 'selectors/viewer/selectStudy';

const { SIDEBAR_WIDTH, DISCLAIMER_MARGIN_BOTTOM, LOCALISATION_TOGGLE_MARGIN_BOTTOM, STUDY_CONTAINER_MARGIN_BOTTOM } =
  APP;
const { HOME, LIST } = Routes;
const { BLACK, WHITE } = colors;
const { SIDEBAR } = loganColors;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-areas:
    'nav nav sidebar'
    'tools main sidebar';
  background-color: ${BLACK};
  color: ${WHITE};
`;

const Main = styled.div`
  height: 100%;
  display: grid;
  grid-area: main;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
`;

const ViewerComponentContainer = styled.div`
  margin-bottom: ${STUDY_CONTAINER_MARGIN_BOTTOM}rem;
`;

const DisclaimerContainer = styled.div`
  margin-bottom: ${DISCLAIMER_MARGIN_BOTTOM}rem;
  display: flex;
  justify-content: center;
`;

const SidebarContainer = styled.div`
  grid-area: sidebar;
  width: ${SIDEBAR_WIDTH}px;
  background-color: ${SIDEBAR};
`;

const ImageControlsContainer = styled.div`
  grid-area: tools;
  display: flex;
`;

const LocalisationToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${LOCALISATION_TOGGLE_MARGIN_BOTTOM}rem;
`;

const ViewerScreen: FC = () => {
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(!retrieveOnboardingDone());
  const studyType = useSelector(selectStudyType);

  useViewerScreenRouter();
  const studiesPaginationProps = useStudiesPagination();
  const { onNewStudyClick, onShareClick, onUploadsClick } = useSidebarNav();
  const sidebarFindingsProps = useSidebarFindings();
  const studyInformation = useDisplayInformation();

  const ViewerComponent = studyType && viewerConfig[studyType].viewerComponent;

  return (
    <Container>
      <ImageControlsContainer>{studyType === 'CXR' && <CxrImageControls />}</ImageControlsContainer>
      <Header />
      <Main>
        <ViewerComponentContainer>{ViewerComponent ? <ViewerComponent /> : null}</ViewerComponentContainer>
        <LocalisationToggleContainer>
          <LocalisationToggle />
        </LocalisationToggleContainer>
        <ConfidenceBar />
        <DisclaimerContainer>
          <Disclaimer />
        </DisclaimerContainer>
      </Main>

      <SidebarContainer>
        {shouldShowOnboarding ? (
          <OnboardingSidebar
            onClick={() => {
              storeOnboardingDone();
              setShouldShowOnboarding(false);
            }}
          />
        ) : (
          <Sidebar
            headerText={studyType ? viewerConfig[studyType].header : ''}
            topSidebarProps={
              isLoggedIn()
                ? {
                    topSidebarType: 'NAV',
                    topSidebarProps: {
                      ...studiesPaginationProps,
                      breadCrumbProps: {
                        onUploadsClick,
                        route: configuration.isDemo ? LIST : HOME,
                        label: configuration.isDemo ? 'Uploads' : 'Worklist',
                      },
                    },
                  }
                : {
                    topSidebarType: 'SHARE',
                    topSidebarProps: {
                      onNewStudyClick,
                      onShareClick,
                    },
                  }
            }
            studyInformation={studyInformation}
            {...sidebarFindingsProps}
          />
        )}
      </SidebarContainer>
    </Container>
  );
};

export default ViewerScreen;
