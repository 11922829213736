import React, { FC } from 'react';

const NoEye: FC = () => {
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.14531 2.54039C1.7596 2.15039 1.7596 1.52039 2.14531 1.13039C2.53102 0.740391 3.16399 0.740391 3.54971 1.13039L19.6805 17.4604C20.0662 17.8504 20.0662 18.4804 19.6805 18.8704C19.2948 19.2604 18.6717 19.2604 18.286 18.8704L15.5959 16.1504C14.2706 16.6704 12.8365 16.9704 11.3332 16.9704C6.38817 16.9704 2.16509 13.8604 0.454102 9.47039C1.21564 7.50039 2.49146 5.80039 4.09366 4.51039L2.14531 2.54039ZM16.2796 9.47031C16.2796 6.71031 14.0642 4.47031 11.3346 4.47031C10.8302 4.47031 10.3456 4.57031 9.88073 4.71031L7.73457 2.54031C8.87194 2.17031 10.0785 1.97031 11.3346 1.97031C16.2796 1.97031 20.5027 5.08031 22.2137 9.46031C21.5313 11.2203 20.4434 12.7603 19.0686 13.9903L16.0423 10.9303C16.1807 10.4703 16.2796 9.98031 16.2796 9.47031ZM11.3343 14.4705C8.60465 14.4705 6.38926 12.2305 6.38926 9.47047C6.38926 8.70047 6.56728 7.97047 6.87388 7.33047L8.42663 8.90047C8.39695 9.08047 8.36728 9.27047 8.36728 9.47047C8.36728 11.1305 9.69256 12.4705 11.3343 12.4705C11.5321 12.4705 11.7101 12.4405 11.8981 12.4005L13.4508 13.9705C12.8079 14.2905 12.0959 14.4705 11.3343 14.4705ZM14.2713 9.14078C14.123 7.74078 13.0351 6.65078 11.6604 6.50078L14.2713 9.14078Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default NoEye;
