import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import TextField from 'components/TextField';
import colors from 'constants/colors';

const { PRIMARY_DARK, PRIMARY_BRIGHT, GRAY_6, DARK_5 } = annaColors;
const { GREY_4 } = colors;

export const SearchContainer = styled.form.attrs({
  role: 'search',
})`
  position: relative;
  width: 30%;
  height: 2.5rem;

  :focus-within {
    svg {
      fill: ${GRAY_6};
    }
  }
`;

export const InsetIconContainer = styled.div.attrs({
  role: 'button',
  'aria-label': 'Search studies',
})`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.5rem;
  left: 0.5rem;
  :hover {
    cursor: pointer;
    svg {
      fill: ${DARK_5};
    }
  }
`;

export const SearchInput = styled(TextField)`
  padding: 0 0.5rem 0 2.5rem;
  font-size: 0.85rem;
  color: ${GREY_4};
  :focus {
    outline: 2px solid ${PRIMARY_BRIGHT};
    box-shadow: ${PRIMARY_DARK} 0px 0px 0px 3px;
    }
  }
`;
