// checkDateTime will return a valid studyDateTime, or empty string if there is not enough valid data
// This is similar to getFormattedDateTime except it we want to return a valid dateTime, not partial results
// if dateTime is not present, then it will be created from  date and time data
export const checkDateTime = (
  dateTime: string | undefined,
  date: string | undefined,
  time: string | undefined,
): string => {
  if (dateTime || (date && time)) {
    return dateTime || `${date} ${time}`;
  } else {
    return '';
  }
};

// getFormattedDataTime returns what is desired to be displayed in the dateTime field
// used in the viewer findings sidebar
// if date or time is missing, the placeholder will substitute for this field
// if both date and time are missing only one placeholder will be returned

const getFormattedDateTime = (
  dateTime: string | undefined,
  date: string | undefined,
  time: string | undefined,
  placeholder = '',
): string => {
  if (dateTime || (date && time)) {
    return dateTime || `${date} ${time}`;
  } else if (date) {
    return `${date} ${placeholder}`;
  } else if (time) {
    return `${placeholder} ${time}`;
  } else {
    return placeholder;
  }
};

export default getFormattedDateTime;
