import { CxrImage, StudyExternalV2Cxr } from '@annaliseai/api-specifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Finding, { Parent } from 'types/study/Finding';
import Image from 'types/study/Image';
import { CxrStudyDescription } from 'types/study/Study';
import CxrFindingViewer, { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';
import CxrImageControls from 'types/viewer/cxrViewer/CxrImageControls';
import CxrImageViewportInfo from 'types/viewer/cxrViewer/CxrImageViewportInfo';
import CxrViews from 'types/viewer/cxrViewer/CxrViews';
import { RootState } from './RootState';

export type CxrViewerState = {
  findingViewsMap: Record<CxrFindingView['uuid'], CxrFindingView>;
  findingViewersMap: Record<Finding['uuid'] | Parent['uuid'], CxrFindingViewer>;
  studyDescription: CxrStudyDescription;
  imagesMetadataMap: Record<Image['uuid'], CxrImage>;
  defaultBaseImageUuid: Image['uuid'] | null;
  activeFinding: CxrViews | null;
  activeView: CxrFindingView['uuid'] | null;
  activeControl: CxrImageControls | null;
  imageViewportInfo: CxrImageViewportInfo;
};

export const initialState: CxrViewerState = {
  findingViewsMap: {},
  findingViewersMap: {},
  studyDescription: null,
  imagesMetadataMap: {},
  defaultBaseImageUuid: null,
  activeFinding: null,
  activeControl: null,
  activeView: null,
  imageViewportInfo: {
    scale: 1,
    windowWidth: 0,
    windowCenter: 0,
  },
};

export const cxrViewerSlice = createSlice({
  name: 'cxrViewer',
  initialState,
  reducers: {
    runInitCxrStudyEffect: (_state, _action: PayloadAction<StudyExternalV2Cxr>) => undefined,
    reset: _ => ({
      ...initialState,
    }),
    setFindingViewersMap: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<CxrViewerState, 'findingViewsMap' | 'findingViewersMap' | 'defaultBaseImageUuid' | 'imagesMetadataMap'>
      >,
    ) => ({
      ...state,
      ...payload,
    }),
    setActiveFinding: (state, { payload: findingUuid }: PayloadAction<Finding['uuid']>) => {
      if (!findingUuid) return { ...state, activeFinding: null };
      const { findingViewersMap } = state;
      const { viewUuids } = findingViewersMap[findingUuid];

      return {
        ...state,
        activeFinding: {
          findingUuid,
          findingViewUuids: viewUuids,
        },
        activeView: viewUuids[0] || null,
        activeControl: 'reset',
      };
    },
    setStudyDescription: (state, { payload }: PayloadAction<CxrViewerState['studyDescription']>) => ({
      ...state,
      studyDescription: payload,
    }),
    setActiveControl: (state, { payload: activeControl }: PayloadAction<CxrViewerState['activeControl']>) => ({
      ...state,
      activeControl,
    }),
    setActiveView: (state, { payload: activeView }: PayloadAction<CxrViewerState['activeView']>) => ({
      ...state,
      activeView,
    }),
    setImageViewportInfo: (
      state,
      { payload: imageViewportInfo }: PayloadAction<CxrViewerState['imageViewportInfo']>,
    ) => ({
      ...state,
      imageViewportInfo,
    }),
  },
});

export const cxrViewerActions = cxrViewerSlice.actions;

export const selectActiveControl = ({ cxrViewer }: RootState): CxrViewerState['activeControl'] =>
  cxrViewer.activeControl;
export const selectImageViewportScale = ({ cxrViewer }: RootState): CxrViewerState['imageViewportInfo']['scale'] =>
  cxrViewer.imageViewportInfo.scale;
export const selectImageViewportWindowWidth = ({
  cxrViewer,
}: RootState): CxrViewerState['imageViewportInfo']['windowWidth'] => cxrViewer.imageViewportInfo.windowWidth;
export const selectImageViewportWindowCenter = ({
  cxrViewer,
}: RootState): CxrViewerState['imageViewportInfo']['windowCenter'] => cxrViewer.imageViewportInfo.windowCenter;
