import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import Routes from 'enums/Routes';

const UploadedStudiesButton = styled(Button)`
  display: flex;
  padding: 0.25em 1em;
  border: none;
  line-height: 2em;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  height: auto;

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
  }

  span {
    margin-left: 1em;
  }
`;

export type BreadCrumbProps = {
  label: string;
  route: Routes;
  onUploadsClick: (route: Routes) => void;
};

const BreadCrumb: FC<BreadCrumbProps> = ({ onUploadsClick, label, route }) => {
  return (
    <UploadedStudiesButton onClick={() => onUploadsClick(route)}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <span>{label}</span>
    </UploadedStudiesButton>
  );
};

export default BreadCrumb;
