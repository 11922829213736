import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import isCustomError from 'typeguards/isCustomError';
import DisplayError from 'types/DisplayError';

const { UNEXPECTED_FRONTEND_ERROR, UNEXPECTED_BACKEND_ERROR, UNEXPECTED_NETWORK_ERROR, CLOUDFLARE_BLOCKED_ERROR } =
  CustomErrors;
const { TOO_MANY_REQUESTS } = StatusCodes;
const { isAxiosError } = axios;

const getAxiosDisplayError = ({ response }: AxiosError): DisplayError => {
  let displayError: DisplayError;

  if (response) {
    const { status } = response;

    if (status === TOO_MANY_REQUESTS) {
      displayError = errorsMapping[CLOUDFLARE_BLOCKED_ERROR];
    } else {
      displayError = errorsMapping[status >= 500 ? UNEXPECTED_BACKEND_ERROR : UNEXPECTED_FRONTEND_ERROR];
    }
  } else {
    displayError = errorsMapping[UNEXPECTED_NETWORK_ERROR];
  }

  return displayError;
};

const getErrorInfo = (error: unknown): { displayError: DisplayError; errorMetadata: string } => {
  let displayError: DisplayError;
  let errorMetadata: string;

  if (isCustomError(error)) {
    const { errorCode } = error;
    displayError = errorsMapping[errorCode];
    errorMetadata = '';
  } else if (isAxiosError(error)) {
    const { url } = error.config;
    displayError = getAxiosDisplayError(error);
    errorMetadata = url ? `"${url}"` : '';
  } else {
    displayError = errorsMapping[UNEXPECTED_FRONTEND_ERROR];
    errorMetadata = 'Unknown error type. Use CustomError to specify the error message.';
  }

  return { displayError, errorMetadata };
};

export default getErrorInfo;
